import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GoogleAdAccountsAtom } from '../../../../../recoil/Data.recoil';
import { AuthorizationTokenAtom, MyInfoAtom } from '../../../../../recoil/Authorization.recoil';
import styles from './Campaign.module.scss';
import GoogleDataSearch from './GoogleDataSearch';
import { UserInfoService } from '../../../../../service/UserInfoService';
import { fetchGoogleCampaignInsights, updateCampaignStatus, syncGoogleCampaigns, fetchGoogleAdGroupInsights, updateAdGroupStatus, updateCampaignDailyBudget, fetchGoogleSyncStatus, GoogleSyncStatusType } from '../../../../../repository/GoogleDataRepository';
import dayjs from 'dayjs';
import _ from 'lodash';
import Switch from 'react-switch';
import { IoMdSync, IoMdArrowDropdown, IoMdArrowDropup, IoMdInformationCircle } from 'react-icons/io';
import CampaignDetailPopup from './CampaignDetailPopup';

interface GoogleDataFilterType {
  customerId: string;
  startDate: string;
  endDate: string;
}

interface SortConfig {
  key: string;
  direction: 'asc' | 'desc';
}

interface GoogleAdGroupInsightResponse {
  adGroup: {
    id: number;
    customerId: string;
    campaignId: string;
    adGroupId: string;
    name: string;
    status: string;
    type: string;
    cpcBids: number;
    cpmBids: number;
    memo: string;
  };
  insight: {
    adgroupId: string;
    fromDate: string;
    toDate: string;
    spend: number;
    clicks: number;
    impressions: number;
    ctr: number;
    cpc: number | null;
    cpm: number;
    visitCount: number;
    purchaseCount: number;
    purchaseRatio: number;
    revenue: number;
    revenuePerVisit: number;
    revenuePerPurchase: number | null;
    roas: number;
  };
}

interface CachedAdGroupData {
  [campaignId: string]: GoogleAdGroupInsightResponse[];
}

interface Campaign {
  id: string;
  name: string;
  status: string;
  memo: string;
}

interface AdGroup {
  id: string;
  name: string;
  status: string;
  memo: string;
}

const Campaign: React.FC = () => {
  UserInfoService();
  
  const token = useRecoilValue(AuthorizationTokenAtom);
  const myInfo = useRecoilValue(MyInfoAtom);
  const googleAccounts = useRecoilValue(GoogleAdAccountsAtom);
  const [filters, setFilters] = useState<GoogleDataFilterType>({
    customerId: '',
    startDate: '',
    endDate: ''
  });
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
  const [expandedCampaignId, setExpandedCampaignId] = useState<string | null>(null);
  const [adGroupData, setAdGroupData] = useState<GoogleAdGroupInsightResponse[]>([]);
  const [cachedAdGroupData, setCachedAdGroupData] = useState<CachedAdGroupData>({});
  const [isLoadingAdGroups, setIsLoadingAdGroups] = useState(false);
  const [editingBudget, setEditingBudget] = useState<{[key: string]: number}>({});
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null);
  const [syncStatus, setSyncStatus] = useState<GoogleSyncStatusType | null>(null);

  useEffect(() => {
    if (token && googleAccounts?.length > 0 && !filters.customerId) {
      const initFilter = {
        customerId: googleAccounts[0].customerId,
        startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
      };
      setFilters(initFilter);
      fetchData(initFilter);
    }
  }, [token, googleAccounts]);

  const fetchData = async (searchFilters: GoogleDataFilterType) => {
    try {
      setIsLoading(true);
      const response = await fetchGoogleCampaignInsights(
        token,
        searchFilters.customerId,
        searchFilters.startDate,
        searchFilters.endDate
      );
      setData(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error('Error fetching campaign data:', error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    setExpandedCampaignId(null);
    setAdGroupData([]);
    setCachedAdGroupData({});
    try {
      const response = await fetchGoogleCampaignInsights(
        token,
        filters.customerId,
        filters.startDate,
        filters.endDate
      );
      setData(response);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (
    campaignId: string, 
    currentStatus: string, 
    campaignName: string,
    channelType: string
  ) => {
    if (channelType === 'VIDEO') {
      alert('비디오 캠페인은 상태를 변경할 수 없습니다.');
      return;
    }

    const newStatus = currentStatus === 'ENABLED' ? 'PAUSED' : 'ENABLED';
    const action = currentStatus === 'ENABLED' ? 'off' : 'on';
    const message = `해당 캠페인을 ${action} 하시겠습니까?`;
    
    const memo = window.prompt(`${message}\n\nMemo`, '');
    if (!memo) return;

    setIsLoading(true);
    try {
      await updateCampaignStatus(token, {
        id: campaignId,
        status: newStatus,
        memo: memo
      });
      
      alert(`[Campaign] ${campaignName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
      fetchData(filters);
    } catch (error: any) {
      console.error('Error updating campaign status:', error);
      alert(error.message || `[Campaign] ${campaignName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n관리자 문의 부탁드립니다.`);
    } finally {
      setIsLoading(false);
    }
  };

  const formatCurrency = (value: number | null | undefined) => {
    if (value == null) return '-';
    return Math.floor(value).toLocaleString();
  };

  const fetchSyncStatus = async () => {
    if (!filters.customerId) return;
    
    try {
      const data = await fetchGoogleSyncStatus(token, filters.customerId);
      setSyncStatus(data);
    } catch (error) {
      console.error('Error fetching sync status:', error);
    }
  };

  useEffect(() => {
    if (token && filters.customerId) {
      fetchSyncStatus();
      const interval = setInterval(fetchSyncStatus, 10000);
      return () => clearInterval(interval);
    }
  }, [token, filters.customerId]);

  const handleSync = async () => {
    if (!filters.customerId || !syncStatus?.syncAble) {
      alert(syncStatus?.description || '현재 동기화할 수 없습니다.');
      return;
    }
    
    setIsSyncing(true);
    try {
      await syncGoogleCampaigns(
        token, 
        filters.customerId,
        filters.startDate,
        filters.endDate
      );
      alert('동기화 요청이 성공되었습니다.\n데이터 동기화는 약 2~3분 정도 소요됩니다.');
      fetchSyncStatus();
      fetchData(filters);
    } catch (error: any) {
      console.error('Error syncing campaigns:', error);
      if (error.code === -4010) {
        alert(error.message);
      } else {
        alert('동기화 중 오류가 발생했습니다.');
      }
    } finally {
      setIsSyncing(false);
    }
  };

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      let aValue = key.includes('.') ? _.get(a, key) : a[key];
      let bValue = key.includes('.') ? _.get(b, key) : b[key];

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setData(sortedData);
  };

  const getSortIcon = (key: string) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'asc' ? (
      <IoMdArrowDropup className={styles.sortIcon} />
    ) : (
      <IoMdArrowDropdown className={styles.sortIcon} />
    );
  };

  const handleRowClick = async (campaignId: string) => {
    if (expandedCampaignId === campaignId) {
      setExpandedCampaignId(null);
      setAdGroupData([]);
      return;
    }

    setExpandedCampaignId(campaignId);
    
    if (cachedAdGroupData[campaignId]) {
      setAdGroupData(cachedAdGroupData[campaignId]);
      return;
    }

    setIsLoadingAdGroups(true);
    try {
      const response = await fetchGoogleAdGroupInsights(
        token,
        filters.customerId,
        campaignId,
        filters.startDate,
        filters.endDate
      );
      setAdGroupData(response);
      setCachedAdGroupData(prev => ({
        ...prev,
        [campaignId]: response
      }));
    } catch (error) {
      console.error('Error fetching ad groups:', error);
      alert('광고그룹 데이터를 불러오는데 실패했습니다.');
    } finally {
      setIsLoadingAdGroups(false);
    }
  };

  const handleAdGroupStatusChange = async (
    adGroupId: string,
    currentStatus: string,
    adGroupName: string,
    campaignId: string,
    campaignChannelType: string
  ) => {
    if (campaignChannelType === 'VIDEO') {
      alert('비디오 캠페인의 광고그룹은 상태를 변경할 수 없습니다.');
      return;
    }

    const newStatus = currentStatus === 'ENABLED' ? 'PAUSED' : 'ENABLED';
    const action = currentStatus === 'ENABLED' ? 'off' : 'on';
    const message = `해당 광고그룹을 ${action} 하시겠습니까?`;
    
    const memo = window.prompt(`${message}\n\nMemo`, '');
    if (!memo) return;

    setIsLoadingAdGroups(true);
    try {
      await updateAdGroupStatus(token, {
        id: adGroupId,
        status: newStatus,
        memo: memo
      });
      
      const updatedData = await fetchGoogleAdGroupInsights(
        token,
        filters.customerId,
        campaignId,
        filters.startDate,
        filters.endDate
      );
      setAdGroupData(updatedData);
      setCachedAdGroupData(prev => ({
        ...prev,
        [campaignId]: updatedData
      }));
      
      alert(`[Ad Group] ${adGroupName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
    } catch (error: any) {
      console.error('Error updating ad group status:', error);
      alert(error.message || `[Ad Group] ${adGroupName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n관리자 문의 부탁드립니다.`);
    } finally {
      setIsLoadingAdGroups(false);
    }
  };

  const showMemo = (name: string, memo: string) => {
    if (!memo) {
      alert(`[${name}] 메모가 없습니다.`);
      return;
    }
    alert(`[${name}] ${memo}`);
  };

  const handleBudgetChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    campaignId: string,
    currentBudget: number
  ) => {
    const value = e.target.value;
    
    // 숫자만 입력되게 하고, 음수 및 0 미만의 값을 막는 로직
    if (!/^\d*$/.test(value)) {
      return;
    }

    const newBudget = value === '' ? 0 : parseInt(value);
    if (newBudget < 0) {
      return;
    }

    setEditingBudget(prev => ({
      ...prev,
      [campaignId]: newBudget
    }));
  };

  const handleBudgetEdit = (
    type: 'increase' | 'decrease',
    campaignId: string,
    currentBudget: number
  ) => {
    const percent = type === 'increase' ? 1.2 : 0.8;
    const newBudget = Math.floor(currentBudget * percent);
    
    setEditingBudget(prev => ({
      ...prev,
      [campaignId]: newBudget
    }));
  };

  const updateBudget = async (
    campaignId: string,
    campaignName: string,
    newBudget: number
  ) => {
    const message = `해당 캠페인의 예산을 ${newBudget.toLocaleString()}원으로 설정하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    
    if (memo === null) return;

    setIsLoading(true);
    try {
      await updateCampaignDailyBudget(token, {
        id: campaignId,
        dailyBudget: newBudget,
        memo: memo
      });
      
      alert(`[Campaign] ${campaignName} 예산변경 성공`);
      fetchData(filters);
      setEditingBudget(prev => {
        const newState = { ...prev };
        delete newState[campaignId];
        return newState;
      });
    } catch (error: any) {
      console.error('Error updating campaign budget:', error);
      alert(error.message || `[Campaign] ${campaignName} 예산변경 실패\n관리자 문의 부탁드립니다.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <GoogleDataSearch
        googleAccounts={googleAccounts}
        filters={filters}
        setFilters={setFilters}
        handleSearch={handleSearch}
      />
      
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <div className={styles.loader} />
          <p>데이터를 불러오는 중입니다...</p>
        </div>
      ) : (
        <div className={styles.tableWrapper}>
          <div className={styles.dataInfo}>
            <div className={styles.totalCount}>
              총 {data.length.toLocaleString()}개의 데이터
            </div>
            <div className={styles.syncInfo}>
              {syncStatus && (
                <div className={styles.syncStatus}>
                  <span className={syncStatus.syncAble ? styles.syncSuccess : styles.syncError}>
                    {syncStatus.description}
                  </span>
                  {syncStatus.lastSyncDate && (
                    <span className={styles.syncTime}>
                      마지막 동기화: {dayjs(syncStatus.lastSyncDate).format('YYYY-MM-DD HH:mm:ss')}
                    </span>
                  )}
                </div>
              )}
              <button
                className={`${styles.syncButton} ${isSyncing ? styles.syncing : ''}`}
                onClick={handleSync}
                disabled={isSyncing || !syncStatus?.syncAble}
              >
                {isSyncing ? <IoMdSync className={styles.syncIcon} /> : '수동 동기화'}
              </button>
            </div>
          </div>
          
          <table className={styles.dataTable}>
            <thead>
              <tr>
                <th onClick={() => handleSort('campaign.name')}>
                  캠페인명 {getSortIcon('campaign.name')}
                </th>
                <th onClick={() => handleSort('campaign.advertisingChannelType')}>
                  광고 채널 {getSortIcon('campaign.advertisingChannelType')}
                </th>
                <th onClick={() => handleSort('campaign.advertisingChannelSubType')}>
                  채널 유형 {getSortIcon('campaign.advertisingChannelSubType')}
                </th>
                <th>상태</th>
                <th onClick={() => handleSort('campaign.budget')}>
                  예산 {getSortIcon('campaign.budget')}
                </th>
                <th onClick={() => handleSort('insight.spend')}>
                  지출 {getSortIcon('insight.spend')}
                </th>
                <th onClick={() => handleSort('insight.revenue')}>
                  매출 {getSortIcon('insight.revenue')}
                </th>
                <th onClick={() => handleSort('insight.roas')}>
                  ROAS {getSortIcon('insight.roas')}
                </th>
                <th onClick={() => handleSort('insight.impressions')}>
                  노출수 {getSortIcon('insight.impressions')}
                </th>
                <th onClick={() => handleSort('insight.ctr')}>
                  CTR {getSortIcon('insight.ctr')}
                </th>
                <th onClick={() => handleSort('insight.clicks')}>
                  클릭수 {getSortIcon('insight.clicks')}
                </th>
                <th onClick={() => handleSort('insight.cpc')}>
                  CPC {getSortIcon('insight.cpc')}
                </th>
                <th onClick={() => handleSort('insight.cpm')}>
                  CPM {getSortIcon('insight.cpm')}
                </th>
                <th onClick={() => handleSort('insight.revenuePerPurchase')}>
                  구매당 수익 {getSortIcon('insight.revenuePerPurchase')}
                </th>
                <th onClick={() => handleSort('insight.purchaseRatio')}>
                  구매율 {getSortIcon('insight.purchaseRatio')}
                </th>
                <th>메모</th>
                <th>자세히보기</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <React.Fragment key={item.campaign.id}>
                  <tr 
                    onClick={() => handleRowClick(item.campaign.campaignId)}
                    className={`${styles.campaignRow} ${expandedCampaignId === item.campaign.campaignId ? styles.expanded : ''}`}
                  >
                    <td>{item.campaign.name}</td>
                    <td>{item.campaign.advertisingChannelType}</td>
                    <td>{item.campaign.advertisingChannelSubType}</td>
                    <td>
                      <Switch
                        checked={item.campaign.status === 'ENABLED'}
                        onChange={() => handleStatusChange(
                          item.campaign.campaignId,
                          item.campaign.status,
                          item.campaign.name,
                          item.campaign.advertisingChannelType
                        )}
                        onColor="#2e7d32"
                        offColor="#d32f2f"
                        onHandleColor="#ffffff"
                        offHandleColor="#ffffff"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={14}
                        width={34}
                        className={styles.switch}
                      />
                    </td>
                    <td>
                      <div className={styles.budgetControls}>
                        <input
                          type="number"
                          value={editingBudget[item.campaign.campaignId] ?? item.campaign.budget}
                          onChange={(e) => handleBudgetChange(e, item.campaign.campaignId, item.campaign.budget)}
                          className={styles.budgetInput}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <div className={styles.budgetButtons}>
                          <button
                            className={styles.budgetBtn}
                            onClick={(e) => {
                              e.stopPropagation();
                              updateBudget(
                                item.campaign.campaignId,
                                item.campaign.name,
                                editingBudget[item.campaign.campaignId] ?? item.campaign.budget
                              );
                            }}
                          >
                            예산 적용
                          </button>
                          <button
                            className={`${styles.percentButton} ${styles.increase}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleBudgetEdit('increase', item.campaign.campaignId, item.campaign.budget);
                            }}
                          >
                            <span className={styles.icon}>↑</span>
                            20%
                          </button>
                          <button
                            className={`${styles.percentButton} ${styles.decrease}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleBudgetEdit('decrease', item.campaign.campaignId, item.campaign.budget);
                            }}
                          >
                            <span className={styles.icon}>↓</span>
                            20%
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>{formatCurrency(item.insight.spend)}</td>
                    <td>{formatCurrency(item.insight.revenue)}</td>
                    <td>{item.insight.roas ? Math.floor(item.insight.roas) + '%' : '-'}</td>
                    <td>{item.insight.impressions?.toLocaleString() ?? '-'}</td>
                    <td>{item.insight.ctr ? Math.floor(item.insight.ctr) + '%' : '-'}</td>
                    <td>{item.insight.clicks?.toLocaleString() ?? '-'}</td>
                    <td>{formatCurrency(item.insight.cpc)}</td>
                    <td>{formatCurrency(item.insight.cpm)}</td>
                    <td>{formatCurrency(item.insight.revenuePerPurchase)}</td>
                    <td>{item.insight.purchaseRatio ? Math.floor(item.insight.purchaseRatio) + '%' : '-'}</td>
                    <td>
                      <button
                        className={styles.memoButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          showMemo(item.campaign.name, item.campaign.memo);
                        }}
                      >
                        <IoMdInformationCircle 
                          className={item.campaign.memo ? styles.hasMemo : styles.noMemo} 
                        />
                      </button>
                    </td>
                    <td>
                      <button
                        className={styles.detailButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedCampaignId(item.campaign.campaignId);
                        }}
                      >
                        자세히보기
                      </button>
                    </td>
                  </tr>
                  {expandedCampaignId === item.campaign.campaignId && (
                    <tr>
                      <td colSpan={13}>
                        <div className={styles.adGroupsContainer}>
                          {isLoadingAdGroups ? (
                            <div className={styles.loadingWrapper}>
                              <div className={styles.loader} />
                              <p>광고그룹 데이터를 불러오는 중입니다...</p>
                            </div>
                          ) : (
                            <table className={styles.adGroupsTable}>
                              <thead>
                                <tr>
                                  <th>광고그룹명</th>
                                  <th>상태</th>
                                  <th>지출</th>
                                  <th>매출</th>
                                  <th>ROAS</th>
                                  <th>노출수</th>
                                  <th>CTR</th>
                                  <th>클릭수</th>
                                  <th>CPC</th>
                                  <th>CPM</th>
                                  <th>구매당 수익</th>
                                  <th>구매율</th>
                                  <th>메모</th>
                                </tr>
                              </thead>
                              <tbody>
                                {adGroupData.map((adGroup) => (
                                  <tr key={adGroup.adGroup.id}>
                                    <td>{adGroup.adGroup.name}</td>
                                    <td>
                                      <Switch
                                        checked={adGroup.adGroup.status === 'ENABLED'}
                                        onChange={() => handleAdGroupStatusChange(
                                          adGroup.adGroup.adGroupId,
                                          adGroup.adGroup.status,
                                          adGroup.adGroup.name,
                                          adGroup.adGroup.campaignId,
                                          item.campaign.advertisingChannelType
                                        )}
                                        onColor="#2e7d32"
                                        offColor="#d32f2f"
                                        onHandleColor="#ffffff"
                                        offHandleColor="#ffffff"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={14}
                                        width={34}
                                        className={styles.switch}
                                      />
                                    </td>
                                    <td>{formatCurrency(adGroup.insight.spend)}</td>
                                    <td>{formatCurrency(adGroup.insight.revenue)}</td>
                                    <td>{adGroup.insight.roas ? Math.floor(adGroup.insight.roas) + '%' : '-'}</td>
                                    <td>{adGroup.insight.impressions?.toLocaleString()}</td>
                                    <td>{adGroup.insight.ctr ? Math.floor(adGroup.insight.ctr) + '%' : '-'}</td>
                                    <td>{adGroup.insight.clicks?.toLocaleString()}</td>
                                    <td>{formatCurrency(adGroup.insight.cpc)}</td>
                                    <td>{formatCurrency(adGroup.insight.cpm)}</td>
                                    <td>{formatCurrency(adGroup.insight.revenuePerPurchase)}</td>
                                    <td>{adGroup.insight.purchaseRatio ? Math.floor(adGroup.insight.purchaseRatio) + '%' : '-'}</td>
                                    <td>
                                      <button
                                        className={styles.memoButton}
                                        onClick={() => showMemo(adGroup.adGroup.name, adGroup.adGroup.memo)}
                                      >
                                        <IoMdInformationCircle 
                                          className={adGroup.adGroup.memo ? styles.hasMemo : styles.noMemo} 
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedCampaignId && (
        <CampaignDetailPopup
          campaignId={selectedCampaignId}
          token={token}
          onClose={() => setSelectedCampaignId(null)}
        />
      )}
    </div>
  );
};

export default Campaign; 