import { useEffect, useState } from 'react'
import { AuthUtils } from '../../../utils/AuthUtils'
import _ from 'lodash'
import { checkVerifyToken } from '../../../repository/LoginRepository'
import { useLocation, useNavigate } from 'react-router-dom'
import Privacy from '../../common/Footer/Privacy'
import Terms from '../../common/Footer/Terms'

const Main = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [shouldCheckLogin, setShouldCheckLogin] = useState(!Boolean(location.state?.showPopup))

  // 팝업 표시 여부 체크
  useEffect(() => {
    if (location.state?.showPopup) {
      setShouldCheckLogin(false)
      if (location.state.showPopup === 'privacy') {
        setShowPrivacy(true)
      } else if (location.state.showPopup === 'terms') {
        setShowTerms(true)
      }
      navigate('/', { replace: true, state: {} })
    }
  }, [location.state, navigate])

  // 로그인 체크
  useEffect(() => {
    if (shouldCheckLogin && !showPrivacy && !showTerms) {
      const token = AuthUtils.getCookie('token')
      if (!_.isEmpty(token)) {
        checkVerifyToken(token)
          .then((res) => {
            if (res.id > 0) {
              window.location.href = '/data/meta/sets'
            }
          })
          .catch((error) => console.error(error))
      } else {
        window.location.href = '/login'
      }
    }
  }, [shouldCheckLogin, showPrivacy, showTerms])

  // 팝업이 닫힐 때 로그인 체크 활성화
  useEffect(() => {
    if (!showPrivacy && !showTerms) {
      setShouldCheckLogin(true)
    }
  }, [showPrivacy, showTerms])

  return (
    <>
      {showPrivacy && (
        <Privacy closePopup={() => setShowPrivacy(false)} />
      )}
      {showTerms && (
        <Terms closePopup={() => setShowTerms(false)} />
      )}
    </>
  )
}

export default Main