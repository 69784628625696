import axios from 'axios'

const apiDomain = process.env.REACT_APP_API_DOMAIN

interface AccountUserCreateRequest {
  email: string;
  type: string;
}

interface AccountUserCreateResponse {
  status: number;
  message?: string;
}

interface UpdateUserTypeRequest {
  type: string;
}

export const createAccountUser = async (data: AccountUserCreateRequest, token: string): Promise<AccountUserCreateResponse> => {
  const response = await axios.post<AccountUserCreateResponse>(
    `${apiDomain}/api/v1/accounts/users`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export const deleteAccountUser = async (userId: number, token: string): Promise<void> => {
  await axios.delete(
    `${apiDomain}/api/v1/accounts/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

export const updateAccountUserType = async (userId: number, type: string, token: string): Promise<void> => {
  await axios.put(
    `${apiDomain}/api/v1/accounts/users/${userId}`,
    { type } as UpdateUserTypeRequest,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const createCafe24Account = async (data: any, token: string): Promise<any> => {
  const response = await axios.put(
    `${apiDomain}/api/v1/ads/cafe24/account`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return response.data;
};

export const updateCafe24Account = async (data: any, token: string): Promise<any> => {
  const response = await axios.put(
    `${apiDomain}/api/v1/ads/cafe24/account`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return response.data;
};

export const deleteCafe24Account = async (token: string): Promise<void> => {
  await axios.delete(
    `${apiDomain}/api/v1/ads/cafe24/account`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const updateUserPassword = async (password: string, token: string): Promise<void> => {
  await axios.put(
    `${apiDomain}/api/v1/accounts/users/password`,
    { password },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
}; 