import { RepositoryUtils } from '../utils/RepositoryUtils'
import { GoogleAdAccountType } from '../types/LoginTypes'
import axios from 'axios'

const apiDomain = process.env.REACT_APP_API_DOMAIN;

export const fetchGoogleAccounts = (token: string) => {
  return RepositoryUtils.axiosGet<GoogleAdAccountType[]>(`${apiDomain}/api/v1/ads/google`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
}

interface GoogleCampaignInsightResponse {
  campaign: {
    id: number;
    campaignId: string;
    name: string;
    status: string;
    startDate: string;
    endDate: string;
    budget: number;
    budgetStatus: string;
  };
  insight: {
    campaignId: string;
    fromDate: string;
    toDate: string;
    spend: number;
    clicks: number;
    impressions: number;
    ctr: number;
    cpc: number;
    cpm: number;
    visitCount: number;
    purchaseCount: number;
    purchaseRatio: number;
    revenue: number;
    revenuePerVisit: number;
    revenuePerPurchase: number;
    roas: number;
  };
}

export const fetchGoogleCampaignInsights = (
  token: string,
  customerId: string,
  from: string,
  to: string
) => {
  return RepositoryUtils.axiosGet<GoogleCampaignInsightResponse[]>(
    `${apiDomain}/api/v1/ads/google/campaigns/insights/_search`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        customerId,
        from,
        to,
      },
    }
  );
};

export interface GoogleAdGroupInsightResponse {
  adGroup: {
    id: number;
    customerId: string;
    campaignId: string;
    adGroupId: string;
    name: string;
    status: string;
    type: string;
    cpcBids: number;
    cpmBids: number;
    memo: string;
  };
  insight: {
    adgroupId: string;
    fromDate: string;
    toDate: string;
    spend: number;
    clicks: number;
    impressions: number;
    ctr: number;
    cpc: number | null;
    cpm: number;
    visitCount: number;
    purchaseCount: number;
    purchaseRatio: number;
    revenue: number;
    revenuePerVisit: number;
    revenuePerPurchase: number | null;
    roas: number;
  };
}

export const fetchGoogleAdGroupInsights = (
  token: string,
  customerId: string,
  campaignId: string,
  from: string,
  to: string
) => {
  return RepositoryUtils.axiosGet<GoogleAdGroupInsightResponse[]>(
    `${apiDomain}/api/v1/ads/google/ad-groups/insights/_search`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        customerId,
        campaignId,
        from,
        to,
      },
    }
  );
};

interface CampaignStatusUpdateRequest {
  id: string;
  status: 'ENABLED' | 'PAUSED';
  memo: string;
}

interface ErrorResponse {
  code: number;
  message: string;
}

export const updateCampaignStatus = async (
  token: string,
  request: CampaignStatusUpdateRequest
) => {
  return RepositoryUtils.axiosPutForGoogle<{ success: boolean }>(
    `${apiDomain}/api/v1/ads/google/campaigns/status`,
    request,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

interface SyncResponse {
  success: boolean;
  message?: string;
  lastSyncTime?: string;
}

export const syncGoogleCampaigns = async (
  token: string, 
  customerId: string,
  from: string,
  to: string
) => {
  try {
    const response = await axios.put<SyncResponse>(
      `${apiDomain}/api/v1/ads/google/sync`,
      { customerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          from,
          to
        }
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw error.response.data;
    }
    throw error;
  }
};

export const getLastSyncTime = (token: string, customerId: string) => {
  return RepositoryUtils.axiosGet<{ lastSyncTime: string }>(
    `${apiDomain}/api/v1/ads/google/campaigns/last-sync-time`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { customerId },
    }
  );
};

export const updateAdGroupStatus = async (
  token: string,
  request: {
    id: string;
    status: string;
    memo: string;
  }
) => {
  try {
    const response = await axios.put(
      `${apiDomain}/api/v1/ads/google/ad-groups/status`,
      request,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw error.response.data;
    }
    throw error;
  }
};

export const updateCampaignDailyBudget = async (
  token: string,
  request: {
    id: string;
    dailyBudget: number;
    memo: string;
  }
) => {
  try {
    const response = await axios.put(
      `${apiDomain}/api/v1/ads/google/campaigns/daily-budget`,
      request,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw error.response.data;
    }
    throw error;
  }
};

export interface CampaignDetailResponse {
  campaign: {
    id: number;
    campaignId: string;
    name: string;
    status: string;
    startDate: string;
    endDate: string;
    budget: number;
    budgetStatus: string;
    advertisingChannelType: string;
    advertisingChannelSubType: string;
    memo: string;
  };
  dailyInsights: Array<{
    campaignId: string;
    fromDate: string;
    toDate: string;
    spend: number;
    clicks: number;
    impressions: number;
    ctr: number;
    cpc: number;
    cpm: number;
    visitCount: number | null;
    purchaseCount: number | null;
    purchaseRatio: number | null;
    revenue: number | null;
    revenuePerVisit: number | null;
    revenuePerPurchase: number | null;
    roas: number | null;
  }>;
  adGroups: Array<{
    id: number;
    customerId: string;
    campaignId: string;
    adGroupId: string;
    name: string;
    status: string;
    type: string;
    cpcBids: number;
    cpmBids: number;
    memo: string;
  }>;
  ads: Array<{
    id: number;
    customerId: string;
    campaignId: string;
    adGroupId: string;
    adId: string;
    name: string;
    type: string;
    status: string;
    finalUrls: string;
    headlines: string;
    descriptions: string;
    textAdHeadline: string;
    textAdDescription1: string;
    textAdDescription2: string;
    approvalStatus: string;
  }>;
}

export const fetchCampaignDetails = async (token: string, campaignId: string) => {
  try {
    const response = await axios.get<CampaignDetailResponse>(
      `${apiDomain}/api/v1/ads/google/campaigns/details`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { campaignId }
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw error.response.data;
    }
    throw error;
  }
};

export interface AdGroupDailyInsight {
  adgroupId: string;
  fromDate: string;
  toDate: string;
  spend: number;
  clicks: number;
  impressions: number;
  ctr: number;
  cpc: number;
  cpm: number;
  visitCount: number;
  purchaseCount: number;
  purchaseRatio: number;
  revenue: number;
  revenuePerVisit: number;
  revenuePerPurchase: number;
  roas: number;
}

export const fetchAdGroupDailyInsights = async (
  token: string,
  adGroupId: string
): Promise<AdGroupDailyInsight[]> => {
  try {
    const response = await axios.get(
      `${apiDomain}/api/v1/ads/google/ad-groups/daily-insights?adGroupId=${adGroupId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw error.response.data;
    }
    throw error;
  }
};

export const updateAdStatus = async (
  token: string,
  request: {
    id: string;
    status: string;
    memo: string;
  }
) => {
  try {
    const response = await axios.put(
      `${apiDomain}/api/v1/ads/google/ad/status`,
      request,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw error.response.data;
    }
    throw error;
  }
};

export interface AdDailyInsight {
  adgroupId: string;
  fromDate: string;
  toDate: string;
  spend: number;
  clicks: number;
  impressions: number;
  ctr: number;
  cpc: number;
  cpm: number;
  visitCount: number;
  purchaseCount: number;
  purchaseRatio: number;
  revenue: number;
  revenuePerVisit: number;
  revenuePerPurchase: number;
  roas: number;
}

export const fetchAdDailyInsights = async (
  token: string,
  adId: string
): Promise<AdDailyInsight[]> => {
  try {
    const response = await axios.get(
      `${apiDomain}/api/v1/ads/google/ads/daily-insights?adId=${adId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw error.response.data;
    }
    throw error;
  }
};

export interface GoogleSyncStatusType {
  syncAble: boolean;
  description: string;
  lastSyncDate: string | null;
}

export const fetchGoogleSyncStatus = async (
  token: string,
  customerId: string
): Promise<GoogleSyncStatusType> => {
  try {
    const response = await axios.get(
      `${apiDomain}/api/v1/ads/google/sync`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          customerId,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw error.response.data;
    }
    throw error;
  }
};

export const fetchGoogleAdsInsights = async (
  token: string,
  customerId: string,
  campaignId: string,
  adGroupId: string,
  from: string,
  to: string
) => {
  try {
    const response = await axios.get(
      `${apiDomain}/api/v1/ads/google/ads/insights/_search`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          customerId,
          campaignId,
          adGroupId,
          from,
          to,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching Google ads insights:', error);
    throw error;
  }
}; 