import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GoogleAdAccountsAtom } from '../../../../../recoil/Data.recoil';
import { AuthorizationTokenAtom } from '../../../../../recoil/Authorization.recoil';
import styles from './AdGroup.module.scss';
import GoogleDataSearch from '../Campaign/GoogleDataSearch';
import Switch from 'react-switch';
import { IoMdInformationCircle } from 'react-icons/io';
import { FaSyncAlt } from 'react-icons/fa';
import { fetchGoogleAdGroupInsights, updateAdGroupStatus, updateAdStatus, fetchGoogleSyncStatus, syncGoogleCampaigns } from '../../../../../repository/GoogleDataRepository';
import dayjs from 'dayjs';
import { fetchGoogleAdsInsights } from '../../../../../repository/GoogleDataRepository';
import { GoogleSyncStatusType } from '../../../../../repository/GoogleDataRepository';

interface GoogleDataFilterType {
  customerId: string;
  startDate: string;
  endDate: string;
}

interface SortConfig {
  key: string;
  direction: 'asc' | 'desc';
}

const AdGroup: React.FC = () => {
  const token = useRecoilValue(AuthorizationTokenAtom);
  const googleAccounts = useRecoilValue(GoogleAdAccountsAtom);
  const [filters, setFilters] = useState<GoogleDataFilterType>({
    customerId: '',
    startDate: '',
    endDate: ''
  });
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
  const [changingStatus, setChangingStatus] = useState<string | null>(null);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [adsData, setAdsData] = useState<{ [key: string]: any[] }>({});
  const [loadingAds, setLoadingAds] = useState<string | null>(null);
  const [changingAdStatus, setChangingAdStatus] = useState<string | null>(null);
  const [syncStatus, setSyncStatus] = useState<GoogleSyncStatusType | null>(null);
  const [isSyncing, setIsSyncing] = useState(false);

  const fetchData = async (searchFilters: GoogleDataFilterType) => {
    if (!searchFilters.customerId) return;
    
    try {
      setIsLoading(true);
      const response = await fetchGoogleAdGroupInsights(
        token,
        searchFilters.customerId,
        '',
        searchFilters.startDate,
        searchFilters.endDate
      );
      setData(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error('Error fetching ad groups data:', error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSyncStatus = async () => {
    if (!filters.customerId) return;
    try {
      const status = await fetchGoogleSyncStatus(token, filters.customerId);
      setSyncStatus(status);
    } catch (error) {
      console.error('Error fetching sync status:', error);
    }
  };

  const handleSyncClick = async () => {
    if (!filters.customerId || isSyncing) return;
    
    try {
      setIsSyncing(true);
      const result = await syncGoogleCampaigns(
        token,
        filters.customerId,
        filters.startDate,
        filters.endDate
      );
      
      if (result.success) {
        alert('동기화가 시작되었습니다.');
        await fetchSyncStatus();
        await fetchData(filters);
      } else {
        alert(result.message || '동기화 요청에 실패했습니다.');
      }
    } catch (error: any) {
      console.error('Error syncing data:', error);
      alert(error.message || '동기화 요청에 실패했습니다.');
    } finally {
      setIsSyncing(false);
    }
  };

  useEffect(() => {
    if (googleAccounts?.length > 0 && !filters.customerId) {
      const initFilter = {
        customerId: googleAccounts[0].customerId,
        startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
      };
      setFilters(initFilter);
    }
  }, [googleAccounts]);

  useEffect(() => {
    if (filters.customerId) {
      fetchSyncStatus();
      const interval = setInterval(fetchSyncStatus, 10000);
      return () => clearInterval(interval);
    }
  }, [filters.customerId]);

  useEffect(() => {
    if (filters.customerId) {
      fetchData(filters);
    }
  }, [filters.customerId]);

  const handleSearch = () => {
    fetchData(filters);
  };

  const formatCurrency = (value: number | null | undefined) => {
    if (value == null) return '-';
    return Math.floor(value).toLocaleString();
  };

  const showMemo = (name: string, memo: string) => {
    if (!memo) {
      alert(`[${name}] 메모가 없습니다.`);
      return;
    }
    alert(`[${name}] ${memo}`);
  };

  const handleStatusChange = async (
    adGroupId: string,
    currentStatus: string,
    adGroupName: string,
    advertisingChannelType: string,
    advertisingChannelSubType: string
  ) => {
    if (advertisingChannelType === 'VIDEO' || advertisingChannelSubType === 'VIDEO_ACTION') {
      alert('비디오 캠페인의 광고그룹은 상태를 변경할 수 없습니다.');
      return;
    }

    const newStatus = currentStatus === 'ENABLED' ? 'PAUSED' : 'ENABLED';
    const action = currentStatus === 'ENABLED' ? 'off' : 'on';
    const message = `해당 광고그룹을 ${action} 하시겠습니까?`;
    
    const memo = window.prompt(`${message}\n\nMemo`, '');
    if (!memo) return;

    try {
      setChangingStatus(adGroupId);
      await updateAdGroupStatus(token, {
        id: adGroupId,
        status: newStatus,
        memo: memo
      });
      
      alert(`[Ad Group] ${adGroupName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
      fetchData(filters);
    } catch (error: any) {
      console.error('Error updating ad group status:', error);
      alert(error.message || `[Ad Group] ${adGroupName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n관리자 문의 부탁드립니다.`);
    } finally {
      setChangingStatus(null);
    }
  };

  const handleAdStatusChange = async (
    adId: string,
    currentStatus: string,
    adName: string,
    adGroupId: string,
    advertisingChannelType: string,
    advertisingChannelSubType: string
  ) => {
    if (advertisingChannelType === 'VIDEO' || advertisingChannelSubType === 'VIDEO_ACTION') {
      alert('비디오 캠페인의 광고는 상태를 변경할 수 없습니다.');
      return;
    }

    const newStatus = currentStatus === 'ENABLED' ? 'PAUSED' : 'ENABLED';
    const action = currentStatus === 'ENABLED' ? 'off' : 'on';
    const message = `해당 광고를 ${action} 하시겠습니까?`;
    
    const memo = window.prompt(`${message}\n\nMemo`, '');
    if (!memo) return;

    try {
      setChangingAdStatus(adId);
      await updateAdStatus(token, {
        id: adId,
        status: newStatus,
        memo: memo
      });
      
      alert(`[Ad] ${adName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
      
      // 광고 데이터 다시 불러오기
      const response = await fetchGoogleAdsInsights(
        token,
        filters.customerId,
        '',
        adGroupId,
        filters.startDate,
        filters.endDate
      );
      setAdsData(prev => ({
        ...prev,
        [adGroupId]: Array.isArray(response) ? response : []
      }));
    } catch (error: any) {
      console.error('Error updating ad status:', error);
      alert(error.message || `[Ad] ${adName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n관리자 문의 부탁드립니다.`);
    } finally {
      setChangingAdStatus(null);
    }
  };

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      let aValue, bValue;

      // 중첩된 객체에서 값을 가져오기
      if (sortConfig.key.includes('.')) {
        const [obj, key] = sortConfig.key.split('.');
        aValue = a[obj][key];
        bValue = b[obj][key];
      } else {
        aValue = a[sortConfig.key];
        bValue = b[sortConfig.key];
      }

      // null 체크
      if (aValue == null) return 1;
      if (bValue == null) return -1;

      // 숫자인 경우 숫자 비교
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // 문자열 비교
      return sortConfig.direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
  };

  const handleRowClick = async (adGroupId: string) => {
    if (expandedRow === adGroupId) {
      setExpandedRow(null);
      return;
    }

    setExpandedRow(adGroupId);
    if (!adsData[adGroupId]) {
      setLoadingAds(adGroupId);
      try {
        const response = await fetchGoogleAdsInsights(
          token,
          filters.customerId,
          '',
          adGroupId,
          filters.startDate,
          filters.endDate
        );
        setAdsData(prev => ({
          ...prev,
          [adGroupId]: Array.isArray(response) ? response : []
        }));
      } catch (error) {
        console.error('Error fetching ads:', error);
        setAdsData(prev => ({
          ...prev,
          [adGroupId]: []
        }));
      } finally {
        setLoadingAds(null);
      }
    }
  };

  return (
    <div className={styles.container}>
      <GoogleDataSearch
        googleAccounts={googleAccounts}
        filters={filters}
        setFilters={setFilters}
        handleSearch={handleSearch}
      />
      
      <div className={styles.syncInfo}>
        {syncStatus && (
          <div className={styles.syncStatus}>
            <span className={syncStatus.syncAble ? styles.syncSuccess : styles.syncError}>
              {syncStatus.description}
            </span>
            {syncStatus.lastSyncDate && (
              <span className={styles.syncTime}>
                마지막 동기화: {dayjs(syncStatus.lastSyncDate).format('YYYY-MM-DD HH:mm:ss')}
              </span>
            )}
          </div>
        )}
        <button
          className={`${styles.syncBtn} ${isSyncing ? styles.syncing : ''}`}
          onClick={handleSyncClick}
          disabled={isSyncing || !syncStatus?.syncAble}
        >
          {isSyncing ? (
            <>
              수동 동기화
            </>
          ) : (
            <>
              수동 동기화
            </>
          )}
        </button>
      </div>
      
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <div className={styles.loader} />
          <p>데이터를 불러오는 중입니다...</p>
        </div>
      ) : (
        <div className={styles.tableWrapper}>
          <table className={styles.dataTable}>
            <thead>
              <tr>
                <th onClick={() => handleSort('adGroup.name')}>
                  광고그룹명 {sortConfig.key === 'adGroup.name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('adGroup.campaignName')}>
                  캠페인명 {sortConfig.key === 'adGroup.campaignName' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('adGroup.advertisingChannelType')}>
                  캠페인타입 {sortConfig.key === 'adGroup.advertisingChannelType' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th>상태</th>
                <th onClick={() => handleSort('insight.spend')}>
                  지출 {sortConfig.key === 'insight.spend' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('insight.revenue')}>
                  매출 {sortConfig.key === 'insight.revenue' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('insight.roas')}>
                  ROAS {sortConfig.key === 'insight.roas' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('insight.impressions')}>
                  노출수 {sortConfig.key === 'insight.impressions' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('insight.ctr')}>
                  CTR {sortConfig.key === 'insight.ctr' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('insight.clicks')}>
                  클릭수 {sortConfig.key === 'insight.clicks' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('insight.cpc')}>
                  CPC {sortConfig.key === 'insight.cpc' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('insight.cpm')}>
                  CPM {sortConfig.key === 'insight.cpm' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('insight.revenuePerPurchase')}>
                  구매당 매출 {sortConfig.key === 'insight.revenuePerPurchase' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('insight.purchaseRatio')}>
                  구매율 {sortConfig.key === 'insight.purchaseRatio' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th>메모</th>
              </tr>
            </thead>
            <tbody>
              {getSortedData().map((item) => (
                <React.Fragment key={item.adGroup.id}>
                  <tr 
                    className={`${styles.adGroupRow} ${expandedRow === item.adGroup.adGroupId ? styles.expanded : ''}`}
                    onClick={() => handleRowClick(item.adGroup.adGroupId)}
                  >
                    <td>{item.adGroup.name}</td>
                    <td>{item.adGroup.campaignName}</td>
                    <td>
                      {item.adGroup.advertisingChannelSubType === 'VIDEO_ACTION' 
                        ? 'VIDEO_ACTION'
                        : item.adGroup.advertisingChannelType}
                    </td>
                    <td>
                      <Switch
                        checked={item.adGroup.status === 'ENABLED'}
                        onChange={() => handleStatusChange(
                          item.adGroup.adGroupId,
                          item.adGroup.status,
                          item.adGroup.name,
                          item.adGroup.advertisingChannelType,
                          item.adGroup.advertisingChannelSubType
                        )}
                        onColor="#2e7d32"
                        offColor="#d32f2f"
                        onHandleColor="#ffffff"
                        offHandleColor="#ffffff"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={14}
                        width={34}
                        className={styles.switch}
                        disabled={changingStatus === item.adGroup.adGroupId}
                      />
                      {changingStatus === item.adGroup.adGroupId && (
                        <div className={styles.statusLoader} />
                      )}
                    </td>
                    <td>{formatCurrency(item.insight.spend)}</td>
                    <td>{formatCurrency(item.insight.revenue)}</td>
                    <td>{item.insight.roas ? Math.floor(item.insight.roas) + '%' : '-'}</td>
                    <td>{item.insight.impressions?.toLocaleString()}</td>
                    <td>{item.insight.ctr ? Math.floor(item.insight.ctr) + '%' : '-'}</td>
                    <td>{item.insight.clicks?.toLocaleString()}</td>
                    <td>{formatCurrency(item.insight.cpc)}</td>
                    <td>{formatCurrency(item.insight.cpm)}</td>
                    <td>{formatCurrency(item.insight.revenuePerPurchase)}</td>
                    <td>{item.insight.purchaseRatio ? Math.floor(item.insight.purchaseRatio) + '%' : '-'}</td>
                    <td>
                      <button
                        className={styles.memoButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          showMemo(item.adGroup.name, item.adGroup.memo);
                        }}
                      >
                        <IoMdInformationCircle 
                          className={item.adGroup.memo ? styles.hasMemo : styles.noMemo} 
                        />
                      </button>
                    </td>
                  </tr>
                  {expandedRow === item.adGroup.adGroupId && (
                    <tr>
                      <td colSpan={14}>
                        <div className={styles.adsContainer}>
                          {loadingAds === item.adGroup.adGroupId ? (
                            <div className={styles.loadingWrapper}>
                              <div className={styles.loader} />
                              <p>광고 데이터를 불러오는 중입니다...</p>
                            </div>
                          ) : adsData[item.adGroup.adGroupId]?.length > 0 ? (
                            <table className={styles.adsTable}>
                              <thead>
                                <tr>
                                  <th>광고명</th>
                                  <th>상태</th>
                                  <th>지출</th>
                                  <th>매출</th>
                                  <th>ROAS</th>
                                  <th>노출수</th>
                                  <th>CTR</th>
                                  <th>클릭수</th>
                                  <th>CPC</th>
                                  <th>CPM</th>
                                  <th>구매당 매출</th>
                                  <th>구매율</th>
                                </tr>
                              </thead>
                              <tbody>
                                {adsData[item.adGroup.adGroupId].map((ad) => (
                                  <tr key={ad.ad.id}>
                                    <td>{ad.ad.name}</td>
                                    <td>
                                      <Switch
                                        checked={ad.ad.status === 'ENABLED'}
                                        onChange={() => handleAdStatusChange(
                                          ad.ad.adId,
                                          ad.ad.status,
                                          ad.ad.name,
                                          item.adGroup.adGroupId,
                                          item.adGroup.advertisingChannelType,
                                          item.adGroup.advertisingChannelSubType
                                        )}
                                        onColor="#2e7d32"
                                        offColor="#d32f2f"
                                        onHandleColor="#ffffff"
                                        offHandleColor="#ffffff"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={14}
                                        width={34}
                                        className={styles.switch}
                                        disabled={changingAdStatus === ad.ad.adId}
                                      />
                                      {changingAdStatus === ad.ad.adId && (
                                        <div className={styles.statusLoader} />
                                      )}
                                    </td>
                                    <td>{formatCurrency(ad.insight.spend)}</td>
                                    <td>{formatCurrency(ad.insight.revenue)}</td>
                                    <td>{ad.insight.roas ? Math.floor(ad.insight.roas) + '%' : '-'}</td>
                                    <td>{ad.insight.impressions?.toLocaleString()}</td>
                                    <td>{ad.insight.ctr ? Math.floor(ad.insight.ctr) + '%' : '-'}</td>
                                    <td>{ad.insight.clicks?.toLocaleString()}</td>
                                    <td>{formatCurrency(ad.insight.cpc)}</td>
                                    <td>{formatCurrency(ad.insight.cpm)}</td>
                                    <td>{formatCurrency(ad.insight.revenuePerPurchase)}</td>
                                    <td>{ad.insight.purchaseRatio ? Math.floor(ad.insight.purchaseRatio) + '%' : '-'}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className={styles.noData}>광고 데이터가 없습니다.</div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdGroup; 