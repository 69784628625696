import React, { useEffect, useState } from 'react';
import S from './Cafe24AccountModal.module.scss';
import { IoClose, IoInformationCircleOutline } from 'react-icons/io5';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AuthorizationTokenAtom, MyInfoAtom } from '../../../../recoil/Authorization.recoil';
import { createCafe24Account, updateCafe24Account } from '../../../../repository/AccountRepository';

interface Cafe24AccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
  initialData?: {
    description: string;
    cafe24Id: string;
    cafe24LoginId?: string;
    cafe24AccountType: string;
  };
}

interface FormData {
  description: string;
  cafe24Id: string;
  cafe24AccountType: '대표 계정' | '부운영자 계정' | '공급자 계정';
  cafe24Password: string;
  passwordConfirm: string;
  cafe24LoginId?: string;
}

const ACCOUNT_TYPES = [
  { value: '부운영자 계정', label: '부운영자 계정', type: 'DEPUTY_ADMIN' },
] as const;

const INITIAL_FORM_STATE: FormData = {
  description: '',
  cafe24Id: '',
  cafe24AccountType: '부운영자 계정',
  cafe24Password: '',
  passwordConfirm: '',
  cafe24LoginId: '',
};

const Cafe24AccountModal: React.FC<Cafe24AccountModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  initialData,
}) => {
  const token = useRecoilValue(AuthorizationTokenAtom);
  const setMyInfo = useSetRecoilState(MyInfoAtom);
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_STATE);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      setError('');
      if (initialData) {
        setFormData({
          description: initialData.description,
          cafe24Id: initialData.cafe24Id,
          cafe24LoginId: initialData.cafe24LoginId || '',
          cafe24AccountType: initialData.cafe24AccountType as '대표 계정' | '부운영자 계정' | '공급자 계정',
          cafe24Password: '',
          passwordConfirm: '',
        });
      } else {
        setFormData(INITIAL_FORM_STATE);
      }
    }
  }, [isOpen, initialData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (formData.cafe24Password !== formData.passwordConfirm) {
      setError('비밀번호가 일치하지 않습니다.');
      return;
    }

    try {
      const { passwordConfirm, ...rest } = formData;
      const accountType = ACCOUNT_TYPES.find(type => type.value === rest.cafe24AccountType)?.type;
      
      const submitData = {
        description: rest.description,
        cafe24Id: rest.cafe24Id,
        cafe24AccountType: accountType,
        ...(rest.cafe24AccountType !== '대표 계정' && { cafe24LoginId: rest.cafe24LoginId }),
        ...((!initialData || rest.cafe24Password) && { cafe24Password: rest.cafe24Password })
      };

      if (initialData) {
        await updateCafe24Account(submitData, token);
        onSuccess('Cafe24 계정이 성공적으로 수정되었습니다.');
      } else {
        await createCafe24Account(submitData, token);
        onSuccess('Cafe24 계정이 성공적으로 연결되었습니다.');
      }

      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : '알 수 없는 오류가 발생했습니다.');
    }
  };

  const handleClose = () => {
    setFormData(INITIAL_FORM_STATE);
    setError('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={S.modalOverlay}>
      <div className={S.modalContent}>
        <div className={S.modalHeader}>
          <h2>{initialData ? 'Cafe24 계정 수정' : 'Cafe24 계정 연결'}</h2>
          <button className={S.closeButton} onClick={handleClose}>
            <IoClose />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className={S.formGroup}>
            <label>계정 유형</label>
            <input
              type="text"
              value="부운영자 계정"
              disabled
              className={S.disabledInput}
            />
          </div>

          <div className={S.formGroup}>
            <label>Cafe24 ID</label>
            <input
              type="text"
              value={formData.cafe24Id}
              onChange={(e) => setFormData(prev => ({ ...prev, cafe24Id: e.target.value }))}
              required
            />
          </div>

          <div className={S.formGroup}>
            <label>로그인 ID</label>
            <input
              type="text"
              value={formData.cafe24LoginId || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, cafe24LoginId: e.target.value }))}
              required
            />
          </div>

          <div className={S.formGroup}>
            <label>비밀번호</label>
            <input
              type="password"
              value={formData.cafe24Password}
              onChange={(e) => setFormData(prev => ({ ...prev, cafe24Password: e.target.value }))}
              required
            />
          </div>

          <div className={S.formGroup}>
            <label>비밀번호 확인</label>
            <input
              type="password"
              value={formData.passwordConfirm}
              onChange={(e) => setFormData(prev => ({ ...prev, passwordConfirm: e.target.value }))}
              required
            />
          </div>

          <div className={S.infoMessage}>
            <IoInformationCircleOutline />
            <span>잘못된 비밀번호 입력 시 동기화가 실패할 수 있습니다.</span>
          </div>

          <div className={S.formGroup}>
            <label>설명</label>
            <input
              type="text"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            />
          </div>

          {error && <div className={S.error}>{error}</div>}

          <div className={S.buttonGroup}>
            <button type="button" className={S.cancelButton} onClick={handleClose}>
              취소
            </button>
            <button type="submit" className={S.submitButton}>
              {initialData ? '수정' : '연결'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Cafe24AccountModal; 