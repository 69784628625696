import React from 'react';
import S from './DeleteConfirmModal.module.scss';
import { IoClose } from 'react-icons/io5';

interface DeleteConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  email?: string;
  message?: string;
  description?: string;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  email,
  message,
  description
}) => {
  if (!isOpen) return null;

  return (
    <div className={S.modalOverlay}>
      <div className={S.modalContent}>
        <div className={S.modalHeader}>
          <h2>삭제 확인</h2>
          <button className={S.closeButton} onClick={onClose}>
            <IoClose />
          </button>
        </div>

        <div className={S.modalBody}>
          {message ? (
            <>
              <p>{message}</p>
              <p className={S.description}>삭제 시 Cafe24 데이터 동기화가 중단됩니다.</p>
              {description && <p className={S.description}>{description}</p>}
            </>
          ) : (
            <>
              <p>사용자 <strong>{email}</strong>을(를) 삭제하시겠습니까?</p>
              <p className={S.description}>삭제 시 Cafe24 데이터 동기화가 중단됩니다.</p>
            </>
          )}
        </div>

        <div className={S.buttonGroup}>
          <button className={S.cancelButton} onClick={onClose}>
            취소
          </button>
          <button className={S.deleteButton} onClick={onConfirm}>
            삭제
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmModal; 