import React, { useState, useEffect, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import dayjs from 'dayjs'
import { FaSyncAlt } from 'react-icons/fa'
import Switch from 'react-switch'
import S from './MetaCampaigns.module.scss'
import { AuthorizationTokenAtom } from '../../../../../recoil/Authorization.recoil'
import { fetchMetaAccounts, fetchMetaCampaignsData, fetchMetaCampaignSets, syncDataManually, changeSetsDailyBudget, changeSetsStatus, fetchMetaCampaignSetAds, changeAdStatus } from '../../../../../repository/MetaDataRepository'
import { apiDomain } from '../../../../../repository/MetaDataRepository'
import { MetaAdAccountType } from '../../../../../types/LoginTypes'
import { MetaCampaignType, MetaCampaignSetType, AD_STATUS, MetaAdType } from '../../../../../types/MetaDataTypes'
import _ from 'lodash'

interface FilterType {
  metaAccountId: string;
  startDate: string;
  endDate: string;
}

interface SortConfig {
  key: string;
  direction: 'asc' | 'desc';
}

interface CampaignSortConfig extends SortConfig {
  key: keyof MetaCampaignType;
}

interface SetSortConfig extends SortConfig {
  key: keyof MetaCampaignSetType;
}

interface AdSortConfig extends SortConfig {
  key: keyof MetaAdType;
}

interface MetaSyncStatusType {
  syncAble: boolean;
  description: string;
  lastSyncDate: string;
}

const MetaCampaigns = () => {
  const token = useRecoilValue(AuthorizationTokenAtom) as string
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [metaAccounts, setMetaAccounts] = useState<MetaAdAccountType[]>([])
  const [data, setData] = useState<MetaCampaignType[]>([])
  const [dateRangeError, setDateRangeError] = useState<string | null>(null)
  const [expandedCampaign, setExpandedCampaign] = useState<string | null>(null)
  const [setsData, setSetsData] = useState<{ [key: string]: MetaCampaignSetType[] }>({})
  const [loadingSets, setLoadingSets] = useState<{ [key: string]: boolean }>({})
  const [campaignSortConfig, setCampaignSortConfig] = useState<CampaignSortConfig>({ key: 'name', direction: 'asc' })
  const [setSortConfig, setSetSortConfig] = useState<SetSortConfig>({ key: 'name', direction: 'asc' })
  const [adsSortConfig, setAdsSortConfig] = useState<AdSortConfig>({ key: 'name', direction: 'asc' })
  const [isSyncing, setIsSyncing] = useState(false)
  const [syncStatus, setSyncStatus] = useState<MetaSyncStatusType | null>(null)
  const [filter, setFilter] = useState<FilterType>({
    metaAccountId: '',
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  })
  const [editingBudget, setEditingBudget] = useState<{ [key: string]: number }>({});
  const [loadingBudgets, setLoadingBudgets] = useState<{ [key: string]: boolean }>({});
  const [loadingStatus, setLoadingStatus] = useState<{ [key: string]: boolean }>({});
  const [adsData, setAdsData] = useState<{ [key: string]: MetaAdType[] }>({});
  const [loadingAds, setLoadingAds] = useState<{ [key: string]: boolean }>({});
  const [expandedSet, setExpandedSet] = useState<string | null>(null);
  const [loadingAdStatus, setLoadingAdStatus] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (token) {
      fetchMetaAccounts(token)
        .then((res) => {
          setMetaAccounts(res)
          if (res.length > 0) {
            setFilter((prev: FilterType) => ({
              ...prev,
              metaAccountId: res[0].metaAccountId
            }))
          }
        })
        .catch((error) => {
          console.error('Failed to fetch Meta accounts:', error)
          const errorMessage = error?.response?.data?.message || error?.message || '알 수 없는 오류가 발생했습니다.'
          setError(`Meta 계정 정보를 불러오는데 실패했습니다. (${errorMessage})`)
        })
    }
  }, [token])

  const handleSearch = () => {
    if (!token) return;
    
    // 날짜 범위 검증
    const start = dayjs(filter.startDate);
    const end = dayjs(filter.endDate);
    const diffDays = end.diff(start, 'day');
    
    if (diffDays > 7) {
      setDateRangeError('최대 7일까지만 조회할 수 있습니다.');
      return;
    }
    
    if (diffDays < 0) {
      setDateRangeError('종료일이 시작일보다 빠를 수 없습니다.');
      return;
    }
    
    setDateRangeError(null);
    setIsLoading(true)
    setError(null)
    
    fetchMetaCampaignsData(token, filter.metaAccountId, filter.startDate, filter.endDate)
      .then((res) => {
        if (!Array.isArray(res)) {
          throw new Error('Invalid data format received')
        }
        setData(res)
      })
      .catch((error) => {
        console.error('Failed to fetch campaign data:', error)
        const errorMessage = error?.response?.data?.message || error?.message || '알 수 없는 오류가 발생했습니다.'
        setError(`캠페인 데이터를 불러오는데 실패했습니다. (${errorMessage})`)
        setData([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCampaignClick = async (campaignId: string) => {
    if (expandedCampaign === campaignId) {
      setExpandedCampaign(null);
      return;
    }
    
    setExpandedCampaign(campaignId);
    
    if (!setsData[campaignId]) {
      setLoadingSets(prev => ({ ...prev, [campaignId]: true }));
      try {
        const sets = await fetchMetaCampaignSets(
          token,
          filter.metaAccountId,
          campaignId,
          filter.startDate,
          filter.endDate
        );
        setSetsData(prev => ({ ...prev, [campaignId]: sets }));
      } catch (error) {
        console.error('Failed to fetch sets data:', error);
      } finally {
        setLoadingSets(prev => ({ ...prev, [campaignId]: false }));
      }
    }
  };

  const handleSort = (key: keyof MetaCampaignType) => {
    setCampaignSortConfig((prevConfig: CampaignSortConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleSetSort = (key: keyof MetaCampaignSetType) => {
    setSetSortConfig((prevConfig: SetSortConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortedData = <T extends MetaCampaignType | MetaCampaignSetType>(items: T[], config: SortConfig & { key: keyof T }) => {
    const sorted = [...items].sort((a, b) => {
      const aValue = a[config.key];
      const bValue = b[config.key];

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return config.direction === 'asc' 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return config.direction === 'asc'
        ? (aValue as number) - (bValue as number)
        : (bValue as number) - (aValue as number);
    });

    return sorted;
  };

  const renderSortArrow = (key: string, currentConfig: { key: string; direction: 'asc' | 'desc' }) => {
    if (currentConfig.key !== key) return null;
    return currentConfig.direction === 'asc' ? ' ↑' : ' ↓';
  };

  const handleBudgetChange = (e: React.ChangeEvent<HTMLInputElement>, setId: string) => {
    const value = Number(e.target.value);
    setEditingBudget(prev => ({
      ...prev,
      [setId]: value
    }));
  };

  const handleBudgetUpdate = async (setId: string, setName: string, newBudget: number) => {
    setLoadingBudgets(prev => ({ ...prev, [setId]: true }));
    try {
      await changeSetsDailyBudget(token, setId, newBudget, null);
      alert('예산이 성공적으로 변경되었습니다.');
      handleSearch();
    } catch (error) {
      console.error('Failed to update budget:', error);
      alert('예산 변경에 실패했습니다.');
    } finally {
      setLoadingBudgets(prev => ({ ...prev, [setId]: false }));
    }
  };

  const handleSetStatusChange = async (setId: string, setName: string, currentStatus: string) => {
    setLoadingStatus(prev => ({ ...prev, [setId]: true }));
    const newStatus = currentStatus === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
    try {
      await changeSetsStatus(token, setId, newStatus as AD_STATUS, null);
      alert(`${setName}의 상태가 변경되었습니다.`);
      // 캠페인 데이터 갱신
      handleSearch();
      // 현재 확장된 캠페인의 세트 데이터 갱신
      if (expandedCampaign) {
        const sets = await fetchMetaCampaignSets(
          token,
          filter.metaAccountId,
          expandedCampaign,
          filter.startDate,
          filter.endDate
        );
        setSetsData(prev => ({ ...prev, [expandedCampaign]: sets }));
      }
    } catch (error) {
      console.error('Failed to change set status:', error);
      alert('상태 변경에 실패했습니다.');
    } finally {
      setLoadingStatus(prev => ({ ...prev, [setId]: false }));
    }
  };

  const handleSetClick = async (setId: string, campaignId: string) => {
    if (expandedSet === setId) {
      setExpandedSet(null);
      return;
    }

    setExpandedSet(setId);

    if (!adsData[setId]) {
      setLoadingAds(prev => ({ ...prev, [setId]: true }));
      try {
        const ads = await fetchMetaCampaignSetAds(
          token,
          filter.metaAccountId,
          campaignId,
          setId,
          filter.startDate,
          filter.endDate
        );
        setAdsData(prev => ({ ...prev, [setId]: ads }));
      } catch (error) {
        console.error('Failed to fetch ads data:', error);
      } finally {
        setLoadingAds(prev => ({ ...prev, [setId]: false }));
      }
    }
  };

  const handleAdsSort = (key: keyof MetaAdType) => {
    setAdsSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleAdStatusChange = async (adId: string, adName: string, status: AD_STATUS) => {
    const action = status === 'ACTIVE' ? 'off' : 'on';
    const message = `해당 광고 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');

    if (memo != null) {
      setLoadingAdStatus(prev => ({ ...prev, [adId]: true }));
      try {
        const result = await changeAdStatus(token, adId, status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE', memo);
        if (result.success) {
          alert(`[AD] ${adName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
          // 캠페인 데이터 갱신
          if (expandedCampaign && expandedSet) {
            // 세트 데이터 새로고침
            const sets = await fetchMetaCampaignSets(
              token,
              filter.metaAccountId,
              expandedCampaign,
              filter.startDate,
              filter.endDate
            );
            setSetsData(prev => ({ ...prev, [expandedCampaign]: sets }));

            // 광고 데이터 새로고침
            const ads = await fetchMetaCampaignSetAds(
              token,
              filter.metaAccountId,
              expandedCampaign,
              expandedSet,
              filter.startDate,
              filter.endDate
            );
            setAdsData(prev => ({ ...prev, [expandedSet]: ads }));
          }
        } else {
          alert(`[AD] ${adName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n 관리자 문의 부탁드립니다.`);
        }
      } catch (error) {
        console.error('Failed to change ad status:', error);
        alert('상태 변경에 실패했습니다.');
      } finally {
        setLoadingAdStatus(prev => ({ ...prev, [adId]: false }));
      }
    }
  };

  const renderAdsTable = (setId: string) => {
    const ads = adsData[setId] || [];
    const sortedAds = [...ads].sort((a, b) => {
      const key = adsSortConfig.key;
      if (a[key] < b[key]) return adsSortConfig.direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return adsSortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

    if (loadingAds[setId]) {
      return (
        <tr>
          <td colSpan={15} className={S.expandedCell}>
            <div className={S.loadingWrapper}>
              <div className={S.loader}></div>
              <span>광고 데이터를 불러오는 중...</span>
            </div>
          </td>
        </tr>
      );
    }

    if (!ads.length) {
      return (
        <tr>
          <td colSpan={15} className={S.expandedCell}>
            <div className={S.noData}>광고 데이터가 없습니다.</div>
          </td>
        </tr>
      );
    }

    return (
      <>
        <tr>
          <td colSpan={15} className={S.expandedCell}>
            <table className={S.subTable}>
              <thead>
                <tr>
                  <th onClick={() => handleAdsSort('name')} className={S.sortable}>
                    광고명{renderSortArrow('name', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('status')} className={S.sortable}>
                    상태{renderSortArrow('status', adsSortConfig)}
                  </th>
                  <th>ON/OFF</th>
                  <th onClick={() => handleAdsSort('spend')} className={S.sortable}>
                    지출{renderSortArrow('spend', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('roas')} className={S.sortable}>
                    ROAS{renderSortArrow('roas', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('revenue')} className={S.sortable}>
                    매출{renderSortArrow('revenue', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('impressions')} className={S.sortable}>
                    노출{renderSortArrow('impressions', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('ctr')} className={S.sortable}>
                    CTR{renderSortArrow('ctr', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('cpc')} className={S.sortable}>
                    CPC{renderSortArrow('cpc', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('cpm')} className={S.sortable}>
                    CPM{renderSortArrow('cpm', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('visitCount')} className={S.sortable}>
                    방문수{renderSortArrow('visitCount', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('purchaseCount')} className={S.sortable}>
                    구매수{renderSortArrow('purchaseCount', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('purchaseRatio')} className={S.sortable}>
                    전환율{renderSortArrow('purchaseRatio', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('revenuePerVisit')} className={S.sortable}>
                    방문당 매출{renderSortArrow('revenuePerVisit', adsSortConfig)}
                  </th>
                  <th onClick={() => handleAdsSort('revenuePerPurchase')} className={S.sortable}>
                    구매당 매출{renderSortArrow('revenuePerPurchase', adsSortConfig)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedAds.map((ad) => (
                  <tr key={ad.adId}>
                    <td>{ad.name}</td>
                    <td>{ad.status}</td>
                    <td>
                      <div className={S.statusCell}>
                        {loadingAdStatus[ad.adId] ? (
                          <div className={S.buttonLoader} />
                        ) : (
                          <Switch
                            onChange={() => handleAdStatusChange(ad.adId, ad.name, ad.status)}
                            checked={ad.status === 'ACTIVE'}
                            className={S.statusSwitch}
                            height={20}
                            width={40}
                            handleDiameter={16}
                            onColor="#2e7d32"
                            offColor="#d32f2f"
                          />
                        )}
                      </div>
                    </td>
                    <td>{ad.spend?.toLocaleString() ?? 0}</td>
                    <td>{ad.roas?.toFixed(2) ?? 0}%</td>
                    <td>{ad.revenue?.toLocaleString() ?? 0}</td>
                    <td>{ad.impressions?.toLocaleString() ?? 0}</td>
                    <td>{ad.ctr?.toFixed(2) ?? 0}%</td>
                    <td>{ad.cpc?.toLocaleString() ?? 0}</td>
                    <td>{ad.cpm?.toLocaleString() ?? 0}</td>
                    <td>{ad.visitCount?.toLocaleString() ?? 0}</td>
                    <td>{ad.purchaseCount?.toLocaleString() ?? 0}</td>
                    <td>{ad.purchaseRatio?.toFixed(2) ?? 0}%</td>
                    <td>{ad.revenuePerVisit?.toLocaleString() ?? 0}</td>
                    <td>{ad.revenuePerPurchase?.toLocaleString() ?? 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      </>
    );
  };

  const renderSetsTable = (campaignId: string) => {
    const sets = setsData[campaignId] || [];
    
    if (loadingSets[campaignId]) {
      return (
        <tr>
          <td colSpan={15} className={S.expandedCell}>
            <div className={S.loadingWrapper}>
              <div className={S.loader}></div>
              <span>세트 데이터를 불러오는 중...</span>
            </div>
          </td>
        </tr>
      );
    }

    if (!sets || sets.length === 0) {
      return (
        <tr>
          <td colSpan={15} className={S.expandedCell}>
            세트 데이터가 없습니다.
          </td>
        </tr>
      );
    }

    const sortedSets = [...sets].sort((a, b) => {
      const aValue = a[setSortConfig.key];
      const bValue = b[setSortConfig.key];
      
      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;
      
      const compareResult = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      return setSortConfig.direction === 'asc' ? compareResult : -compareResult;
    });

    return (
      <tr>
        <td colSpan={14} className={S.expandedCell}>
          <table className={S.subTable}>
            <thead>
              <tr>
                <th onClick={() => handleSetSort('name')} className={S.sortable}>
                  세트명{renderSortArrow('name', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('status')} className={S.sortable}>
                  상태{renderSortArrow('status', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('dailyBudget')} className={S.sortable}>
                  일일 예산{renderSortArrow('dailyBudget', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('spend')} className={S.sortable}>
                  지출{renderSortArrow('spend', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('roas')} className={S.sortable}>
                  ROAS{renderSortArrow('roas', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('revenue')} className={S.sortable}>
                  매출{renderSortArrow('revenue', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('impressions')} className={S.sortable}>
                  노출{renderSortArrow('impressions', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('ctr')} className={S.sortable}>
                  CTR{renderSortArrow('ctr', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('cpc')} className={S.sortable}>
                  CPC{renderSortArrow('cpc', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('cpm')} className={S.sortable}>
                  CPM{renderSortArrow('cpm', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('visitCount')} className={S.sortable}>
                  방문수{renderSortArrow('visitCount', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('purchaseCount')} className={S.sortable}>
                  구매수{renderSortArrow('purchaseCount', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('purchaseRatio')} className={S.sortable}>
                  전환율{renderSortArrow('purchaseRatio', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('revenuePerVisit')} className={S.sortable}>
                  방문당 매출{renderSortArrow('revenuePerVisit', setSortConfig)}
                </th>
                <th onClick={() => handleSetSort('revenuePerPurchase')} className={S.sortable}>
                  구매당 매출{renderSortArrow('revenuePerPurchase', setSortConfig)}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedSets.map((set) => (
                <React.Fragment key={set.setId}>
                  <tr 
                    onClick={() => handleSetClick(set.setId, campaignId)}
                    className={`${S.setRow} ${expandedSet === set.setId ? S.expanded : ''}`}
                  >
                    <td>{set.name}</td>
                    <td>
                      <div className={S.statusCell}>
                        <Switch
                          onChange={() => handleSetStatusChange(set.setId, set.name, set.status)}
                          checked={set.status === 'ACTIVE'}
                          height={20}
                          width={40}
                          onColor="#2e7d32"
                          offColor="#d32f2f"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          className={S.statusSwitch}
                          disabled={loadingStatus[set.setId]}
                        />
                        <span>{loadingStatus[set.setId] ? '변경중...' : set.status}</span>
                      </div>
                    </td>
                    <td>
                      <div className={S.budgetControls} onClick={(e) => e.stopPropagation()}>
                        <input
                          type="number"
                          value={editingBudget[set.setId] ?? set.dailyBudget}
                          onChange={(e) => handleBudgetChange(e, set.setId)}
                          className={S.budgetInput}
                        />
                        <button
                          className={`${S.applyButton} ${loadingBudgets[set.setId] ? S.loading : ''}`}
                          onClick={() => handleBudgetUpdate(set.setId, set.name, editingBudget[set.setId] ?? set.dailyBudget)}
                          disabled={editingBudget[set.setId] === undefined || loadingBudgets[set.setId]}
                        >
                          {loadingBudgets[set.setId] ? (
                            <div className={S.buttonLoadingWrapper}>
                              <div className={S.buttonLoader}></div>
                              <span>적용중</span>
                            </div>
                          ) : (
                            '적용'
                          )}
                        </button>
                      </div>
                    </td>
                    <td>{set.spend?.toLocaleString() ?? 0}</td>
                    <td>{set.roas?.toFixed(2) ?? 0}%</td>
                    <td>{set.revenue?.toLocaleString() ?? 0}</td>
                    <td>{set.impressions?.toLocaleString() ?? 0}</td>
                    <td>{set.ctr?.toFixed(2) ?? 0}%</td>
                    <td>{set.cpc?.toLocaleString() ?? 0}</td>
                    <td>{set.cpm?.toLocaleString() ?? 0}</td>
                    <td>{set.visitCount?.toLocaleString() ?? 0}</td>
                    <td>{set.purchaseCount?.toLocaleString() ?? 0}</td>
                    <td>{set.purchaseRatio?.toFixed(2) ?? 0}%</td>
                    <td>{set.revenuePerVisit?.toLocaleString() ?? 0}</td>
                    <td>{set.revenuePerPurchase?.toLocaleString() ?? 0}</td>
                  </tr>
                  {expandedSet === set.setId && renderAdsTable(set.setId)}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    );
  };

  const sortedData = getSortedData(data, campaignSortConfig);

  // 동기화 상태 조회
  const fetchSyncStatus = useCallback(() => {
    if (!_.isEmpty(token) && filter.metaAccountId) {
      fetch(`${apiDomain}/api/v1/ads/meta/sync?accountId=${filter.metaAccountId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then((res) => {
          setSyncStatus(res);
        })
        .catch((error) => {
          console.error('동기화 상태 조회 실패:', error);
        });
    }
  }, [token, filter.metaAccountId]);

  // 컴포넌트 마운트 시와 10초마다 동기화 상태 업데이트
  useEffect(() => {
    fetchSyncStatus();
    
    const intervalId = setInterval(() => {
      fetchSyncStatus();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchSyncStatus]);

  // 동기화 버튼 클릭 핸들러
  const handleSyncClick = async () => {
    if (!syncStatus?.syncAble) {
      alert(syncStatus?.description || '현재 동기화할 수 없습니다.');
      return;
    }

    setIsSyncing(true);
    try {
      const result = await syncDataManually(token, filter.startDate, filter.endDate);
      
      if (result.success) {
        alert('동기화 요청이 성공되었습니다.\n데이터 동기화는 약 2~3분 정도 소요됩니다.');
        fetchSyncStatus();
        fetchData();
      } else {
        alert('동기화 실패: ' + result.message);
      }
    } catch (error) {
      console.error('동기화 실패:', error);
      alert('동기화 중 오류가 발생했습니다.');
    } finally {
      setIsSyncing(false);
    }
  };

  const fetchData = useCallback(() => {
    setIsLoading(true);
    fetchMetaCampaignsData(
      token,
      filter.metaAccountId,
      filter.startDate,
      filter.endDate
    )
      .then((res) => {
        if (res) {
          setData(res);
          setError('');
        } else {
          setData([]);
          setError('데이터를 불러오는데 실패했습니다.');
        }
      })
      .catch((e) => {
        console.error(e);
        setData([]);
        setError('데이터를 불러오는데 실패했습니다.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token, filter]);

  useEffect(() => {
    if (!_.isEmpty(token) && filter.metaAccountId) {
      fetchData();
    }
  }, [token, filter, fetchData]);

  return (
    <div className={S.container}>
      <div className={S.pageHeader}>
        <h1>Meta 캠페인</h1>
        <p className={S.pageDescription}>캠페인 데이터를 조회합니다</p>
      </div>

      <div className={S.searchSection}>
        <div className={S.searchContainer}>
          <h2>필터</h2>
          <div className={S.searchGroup}>
            <div className={S.flexContainer}>
              <div className={S.searchItem}>
                <label>계정</label>
                <select
                  value={filter.metaAccountId}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFilter(prev => ({ ...prev, metaAccountId: e.target.value }))}
                >
                  {metaAccounts.map((account) => (
                    <option key={account.metaAccountId} value={account.metaAccountId}>
                      {account.metaAccountName}
                    </option>
                  ))}
                </select>
              </div>
              <div className={S.searchItem}>
                <label>조회 기간</label>
                <div className={S.dateContainer}>
                  <input
                    type="date"
                    value={filter.startDate}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilter(prev => ({ ...prev, startDate: e.target.value }))}
                    max={filter.endDate}
                  />
                  <span className={S.dateSeparator}>~</span>
                  <input
                    type="date"
                    value={filter.endDate}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilter(prev => ({ ...prev, endDate: e.target.value }))}
                    min={filter.startDate}
                  />
                </div>
                {dateRangeError && (
                  <span className={S.dateError}>{dateRangeError}</span>
                )}
              </div>
              <div className={S.buttonContainer}>
                <button className={S.searchButton} onClick={handleSearch}>
                  조회
                </button>
                <div className={S.syncInfo}>
                  <button
                    className={`${S.syncBtn} ${isSyncing ? S.syncing : ''}`}
                    onClick={handleSyncClick}
                    disabled={isSyncing || !syncStatus?.syncAble}
                  >
                    {isSyncing ? <FaSyncAlt className={S.syncIcon} /> : '동기화'}
                  </button>
                  {syncStatus && (
                    <div className={S.syncStatus}>
                      <span className={syncStatus.syncAble ? S.syncSuccess : S.syncError}>
                        {syncStatus.description}
                      </span>
                      {syncStatus.lastSyncDate && (
                        <span className={S.syncTime}>
                          마지막 동기화: {dayjs(syncStatus.lastSyncDate).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={S.dataSection}>
        {isLoading ? (
          <div className={S.loadingWrapper}>
            <div className={S.loader}></div>
            <span>데이터를 불러오는 중...</span>
          </div>
        ) : error ? (
          <div className={S.errorMessage}>
            <p>{error}</p>
            <button className={S.retryButton} onClick={handleSearch}>
              다시 시도
            </button>
          </div>
        ) : data.length === 0 ? (
          <div className={S.noData}>데이터가 없습니다.</div>
        ) : (
          <div className={S.tableWrapper}>
            <table className={S.table}>
              <thead>
                <tr>
                  <th onClick={() => handleSort('name')} className={S.sortable}>
                    캠페인명{renderSortArrow('name', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('status')} className={S.sortable}>
                    상태{renderSortArrow('status', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('spend')} className={S.sortable}>
                    지출{renderSortArrow('spend', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('roas')} className={S.sortable}>
                    ROAS{renderSortArrow('roas', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('revenue')} className={S.sortable}>
                    매출{renderSortArrow('revenue', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('impressions')} className={S.sortable}>
                    노출{renderSortArrow('impressions', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('ctr')} className={S.sortable}>
                    CTR{renderSortArrow('ctr', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('cpc')} className={S.sortable}>
                    CPC{renderSortArrow('cpc', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('cpm')} className={S.sortable}>
                    CPM{renderSortArrow('cpm', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('visitCount')} className={S.sortable}>
                    방문수{renderSortArrow('visitCount', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('purchaseCount')} className={S.sortable}>
                    구매수{renderSortArrow('purchaseCount', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('purchaseRatio')} className={S.sortable}>
                    전환율{renderSortArrow('purchaseRatio', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('revenuePerVisit')} className={S.sortable}>
                    방문당 매출{renderSortArrow('revenuePerVisit', campaignSortConfig)}
                  </th>
                  <th onClick={() => handleSort('revenuePerPurchase')} className={S.sortable}>
                    구매당 매출{renderSortArrow('revenuePerPurchase', campaignSortConfig)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((campaign) => (
                  <React.Fragment key={campaign.campaignId}>
                    <tr 
                      onClick={() => handleCampaignClick(campaign.campaignId)}
                      className={`${S.campaignRow} ${expandedCampaign === campaign.campaignId ? S.expanded : ''}`}
                    >
                      <td>{campaign.name}</td>
                      <td>{campaign.status}</td>
                      <td>{campaign.spend?.toLocaleString() ?? 0}</td>
                      <td>{campaign.roas?.toFixed(2) ?? 0}%</td>
                      <td>{campaign.revenue?.toLocaleString() ?? 0}</td>
                      <td>{campaign.impressions?.toLocaleString() ?? 0}</td>
                      <td>{campaign.ctr?.toFixed(2) ?? 0}%</td>
                      <td>{campaign.cpc?.toLocaleString() ?? 0}</td>
                      <td>{campaign.cpm?.toLocaleString() ?? 0}</td>
                      <td>{campaign.visitCount?.toLocaleString() ?? 0}</td>
                      <td>{campaign.purchaseCount?.toLocaleString() ?? 0}</td>
                      <td>{campaign.purchaseRatio?.toFixed(2) ?? 0}%</td>
                      <td>{campaign.revenuePerVisit?.toLocaleString() ?? 0}</td>
                      <td>{campaign.revenuePerPurchase?.toLocaleString() ?? 0}</td>
                    </tr>
                    {expandedCampaign === campaign.campaignId && renderSetsTable(campaign.campaignId)}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default MetaCampaigns 