import React, { useEffect } from 'react';
import styles from './Privacy.module.scss';
import { useLocation } from 'react-router-dom';

interface PrivacyProps {
  closePopup?: () => void;
}

const Privacy: React.FC<PrivacyProps> = ({ closePopup }) => {
  const location = useLocation();
  const isPopup = Boolean(closePopup);

  useEffect(() => {
    if (isPopup) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }
  }, [isPopup]);

  const content = (
    <div className={styles.content}>
      <div className={styles.header}>
        <h2>개인정보 처리방침</h2>
        {isPopup && (
          <button className={styles.closeButton} onClick={closePopup}>
            X
          </button>
        )}
      </div>
      <div className={styles.body}>
        <p>
          <strong>주식회사 애드커넥션 개인정보처리방침</strong>
        </p>
        <p>
          (주)애드커넥션(이하 "회사")은 정보주체의 자유와 권리 보호를 위해 개인정보 보호법 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 개인정보
          보호법 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침(이하 "본
          방침")을 수립∙공개합니다.
        </p>
        <p>
          <strong>제1조 개인정보의 처리 목적</strong><br />
          ① 회사는 본항에 기재된 다음 각호의 목적을 위해 개인정보를 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에
          따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </p>
        <ul>
          <li>
            <strong>홈페이지 회원가입 및 관리:</strong> 회원가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별 및 인증, 회원자격 유지 및 관리, 서비스 부정 이용 방지, 만 14세
            미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지 및 통지, 고충 처리, 분쟁 조정을 위한 기록 보존을 목적으로 개인정보를 처리합니다.
          </li>
          <li>
            <strong>서비스 문의 및 민원 사무 처리:</strong> 문의 및 민원인의 신원 확인, 문의 및 민원사항 확인, 사실 조사를 위한 연락 및 통지, 답변 및 대응, 처리 결과 통보를
            목적으로 개인정보를 처리합니다.
          </li>
          <li>
            <strong>서비스 제공 및 서비스 성능 개선:</strong> 서비스의 제공 및 서비스 성능 개선, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인 인증, 정산을 목적으로 개인정보를
            처리합니다.
          </li>
          <li>
            <strong>결제:</strong> 서비스 요금 결제, 청구서 발송, 결제카드 등록 및 결제, 정산, 추심을 목적으로 개인정보를 처리합니다.
          </li>
          <li>
            <strong>마케팅 및 광고에의 활용:</strong> 신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여 기회 제공, 인구통계학적 특성에 따른 서비스 제공,
            서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용 통계 및 설문에 대한 통계 수집 목적으로 개인정보를 처리합니다.
          </li>
        </ul>
        <p>
          <strong>제2조 개인정보의 항목 및 수집 방법</strong><br />
          ① 회사는 다음 각 목적에 따라 다음의 개인정보 항목을 처리하고 있습니다.
        </p>
        <ul>
          <li><strong>홈페이지 회원가입 및 관리</strong><br />
            필수항목: 이름, 이메일, 회사명, 아이디, 비밀번호<br />
            선택항목: 전화번호
          </li>
          <li><strong>서비스 문의 및 민원 사무 처리</strong><br />
            필수항목: 이름, 이메일, 회사명
          </li>
          <li><strong>서비스 제공 및 서비스 성능 개선</strong><br />
            필수항목: 이름, 이메일, 회사명<br />
            선택항목: 전화번호
          </li>
          <li><strong>결제</strong><br />
            필수항목: 이름, 업체명, 광고 예산, 사업자등록번호, 예금주
          </li>
          <li><strong>마케팅 및 광고에의 활용</strong><br />
            필수항목: 이름, 이메일, 회사명<br />
            선택항목: 전화번호
          </li>
        </ul>
        <p>
          ② 서비스 이용 과정에서 아래의 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.<br />
          IP주소, 쿠키, MAC 주소, 서비스 이용기록, 방문기록
        </p>
        <p>
          ③ 회사는 정보주체의 기본적 인권을 침해할 우려가 있는 민감한 정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향, 범죄기록, 건강 상태 등)는 정보주체의 동의 또는 법령의
          규정에 의한 경우 이외에는 수집하지 않습니다.
        </p>
        <p>
          ④ 정보주체는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 단, 필수 동의 사항에 동의를 거부할 경우, 서비스 이용이 불가능하거나 서비스 이용 목적에 따른 서비스 제공에 제한이
          따르게 됩니다.
        </p>
        <p>
          <strong>제3조 개인정보의 처리 및 보유 기간</strong><br />
          ① 회사는 법령에 따른 개인정보 보유 및 이용기간 또는 정보 주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용기간 내에서 개인정보를 처리 및 보유합니다.
        </p>
        <p>
          ② 각각의 개인정보 보유 및 이용 기간은 다음과 같습니다.
        </p>
        <ul>
          <li><strong>홈페이지 회원가입 및 관리:</strong> 홈페이지 회원가입 및 관리와 관련한 개인정보는 회원의 홈페이지 탈퇴 시까지 보유 및 이용됩니다. 다만, 다음의 사유에 해당하는
            경우에는 해당 사유 종료 시까지 보유 및 이용됩니다.
          </li>
          <li><strong>서비스 문의 및 민원 사무 처리:</strong> 개인정보 수집 및 이용에 관한 동의일로부터 서비스 문의 및 민원 사무가 완료 후 5일이 경과한 때까지 보유 및 이용됩니다.
          </li>
          <li><strong>서비스 제공 및 서비스 성능 개선:</strong> 개인정보 수집 및 이용에 관한 동의일로부터 회원 탈퇴 후 5일이 경과한 때까지 보유 및 이용됩니다.</li>
          <li><strong>결제:</strong> 개인정보 수집 및 이용에 관한 동의일로부터 서비스 공급완료 및 요금결제∙정산 완료 후 5일이 경과한 때까지 보유 및 이용됩니다.</li>
        </ul>
        <p>
          <strong>제4조 개인정보의 제3자 제공에 관한 사항</strong><br />
          ① 회사는 정보 주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조 및 제18조에 해당하는 경우에만 최소한의 범위의 개인정보를 제3자에게 제공합니다.
        </p>
        <p>
          ② 회사는 타 법령에 특별한 규정이 있는 경우를 제외하고, 개인정보를 고지 또는 명시한 범위를 초과하여 이용하거나 제3자에게 제공하지 않습니다.
        </p>
        <p>
          <strong>제5조 개인정보의 처리 위탁</strong><br />
          ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
        </p>
        <ul>
          <li>위탁받는 자(수탁자) : Amazon Web Services, Inc.<br />
            위탁 업무 : 데이터 보관 및 전산시스템 운용∙관리
          </li>
        </ul>
        <p>
          ② 회사는 위탁계약 체결 시 개인정보 보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적∙관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리∙감독, 손해배상 등 책임에 관한
          사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
        </p>
        <p>
          <strong>제6조 개인정보의 국외 이전</strong><br />
          ① 회사는 개인정보 보호법 제28조의8 제1항 제3호 가목에 근거하여 아래와 같이 개인정보를 국외에 이전합니다.<br />
          업체명: Amazon Web Services, Inc.<br />
          이전 국가, 일시 및 방법: 미국, 서비스 이용 시점에 네트워크를 통한 전송<br />
          이전되는 항목: 제1조의 수집 목적, 항목, 처리 및 보유기간과 동일함<br />
          이전받는 자의 이용목적: 제1조의 수집 목적, 항목, 처리 및 보유기간과 동일함<br />
          보유 및 이용기간: 제1조의 수집 목적, 항목, 처리 및 보유기간과 동일함<br />
          정보관리책임자 연락처: https://aws.amazon.com/compliance/contact
        </p>
        <p>
          <strong>제7조 정보주체의 권리·의무 및 그 행사 방법</strong><br />
          ① 이용자는 개인정보 주체로서 다음과 같은 권리를 행사할 수 있습니다.<br />
          정보주체는 회사에 대해 언제든지 개인정보 열람, 정정, 삭제, 처리 정지 요구 등의 권리를 행사할 수 있습니다.
        </p>
        <p>
          <strong>제8조 개인정보의 파기절차 및 방법</strong><br />
          ① 회사는 원칙적으로 개인정보 보유기간의 경과, 개인정보 처리 목적이 달성된 경우 등 그 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        </p>
        <p>
          <strong>제9조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</strong><br />
          ① 회사는 개별적인 맞춤 서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
        </p>
        <p>
          <strong>제10조 행태정보의 수집∙이용 및 거부 등에 관한 사항</strong><br />
          ① 회사는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위해서 행태정보를 수집·이용하고 있습니다. 정보주체는 행태정보의 수집ㆍ이용을 거부할
          수 있으나, 이 경우 서비스 이용이 제한될 수 있습니다.
        </p>
        <p>
          <strong>제11조 수집목적과 합리적으로 관련된 범위 내의 개인정보 이용 및 제공</strong><br />
          ① 회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을 고려하여, 이용자의 동의 없이 개인정보를 이용 또는 제3자에게 제공할 수 있습니다.
        </p>
        <ul>
          <li>1. 당초 수집 목적과 관련성이 있는지 여부</li>
          <li>2. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이 있는지 여부</li>
          <li>3. 이용자의 이익을 부당하게 침해하는지 여부</li>
          <li>4. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</li>
        </ul>
        <p>
          <strong>제12조 개인정보 보호책임자</strong><br />
          ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.<br />
          개인정보보호 책임자: 박우일, CEO<br />
          연락처: wooil@adconnection.co.kr
        </p>
        <p>
          <strong>제13조 권익침해 구제방법</strong><br />
          정보주체는 개인정보침해로 인한 구제를 받기 위하여 아래 기관에 분쟁 해결이나 상담 등을 신청할 수 있습니다.
        </p>
        <ul>
          <li>개인정보침해 신고센터: https://privacy.kisa.or.kr (국번없이 118)</li>
          <li>개인정보 분쟁조정위원회: https://www.kopico.go.kr (국번없이) 1833-6972</li>
          <li>대검찰청 사이버범죄수사단: http://www.spo.go.kr (국번없이) 1301</li>
          <li>경찰청 사이버안전지킴이: https://www.police.go.kr (국번없이) 182</li>
        </ul>
        <p>
          <strong>제14조 개인정보 처리방침 변경</strong><br />
          ① 본 개인정보처리방침은 시행일로부터 적용되며, 관계 법령 또는 회사의 필요 등에 따라 수정, 추가 및 삭제될 수 있습니다. 이 경우 지체없이 회사 홈페이지의 공지사항을 통해 고지하며 별도
          공지가 없는 한 고지일로부터 7일이 경과된 후에 시행됩니다.
        </p>
        <p>
          <strong>제15조 개인정보의 안전성 확보 조치</strong><br />
          ① 회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적∙관리적 및 물리적 조치를 하고 있습니다.
        </p>
        <ul>
          <li>1. 정기적인 자체 감사 실시</li>
          <li>2. 개인정보 취급 직원의 최소화 및 교육</li>
          <li>3. 내부관리계획의 수립 및 시행</li>
          <li>4. 해킹 등에 대비한 기술적 대책</li>
          <li>5. 개인정보의 암호화</li>
          <li>6. 접속 기록의 보관 및 위∙변조 방지</li>
          <li>7. 개인정보에 대한 접근 제한</li>
          <li>8. 문서 보안을 위한 잠금 장치 사용</li>
          <li>9. 비인가자에 대한 출입 통제</li>
        </ul>
        <p>
          <strong>제16조 매체 정책에 관한 사항</strong><br />
          구글 API를 통해 수집한 정보의 이용 및 다른 앱 전송 시 구글 API 서비스 이용 데이터 정책을 준수하며, 제한된 이용 요구사항을 포함합니다.
        </p>
        <p>
          <strong>제17조 정보 주체 이외로부터 수집한 개인정보의 수집 출처 등 통지</strong><br />
          ① 회사가 정보 주체 이외로부터 수집한 개인정보를 처리하는 때에 정보 주체의 요구가 있는 경우에는 개인정보 보호법 제20조 제1항에 의거하여 개인정보 수집 출처 및 처리 목적을 조회할 수 있도록
          하고, 개인정보 처리 정지를 요구하거나 동의를 철회하는 경우에도 지체없이 답변 및 처리할 것입니다.
        </p>
        <p>
          <strong>부칙(시행일)</strong><br />
          본 개인정보처리방침은 2024년 9월 1일부터 시행됩니다.
        </p>
      </div>
    </div>
  );

  if (isPopup) {
    return (
      <div className={styles.popupOverlay}>
        <div className={styles.popupContent}>
          {content}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.pageContainer}>
      {content}
    </div>
  );
};

export default Privacy;
