import React, { useEffect, useState } from 'react'
import S from './MyAccount.module.scss';
import { UserInfoService } from '../../../../service/UserInfoService';
import { AuthorizationTokenAtom, MyInfoAtom } from '../../../../recoil/Authorization.recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MetaAdAccountType, TikTokAdAccountType, GoogleAdAccountType, UserTypeEnum } from '../../../../types/LoginTypes'
import { IoChevronDownOutline, IoChevronUpOutline, IoHelpCircleOutline, IoChevronDown, IoPencil, IoTrash, IoAdd } from 'react-icons/io5';
import { SiMeta, SiTiktok, SiGoogle } from 'react-icons/si';
import UserManageModal from './UserManageModal';
import {
  deleteAccountUser,
  deleteCafe24Account,
  updateAccountUserType,
  updateUserPassword,
} from '../../../../repository/AccountRepository';
import axios from 'axios';
import DeleteConfirmModal from './DeleteConfirmModal';
import EditUserTypeModal from './EditUserTypeModal';
import Cafe24AccountModal from './Cafe24AccountModal';

interface UserForDelete {
  id: number;
  email: string;
}

interface UserForEdit {
  id: number;
  email: string;
  type: string;
}

const MyAccount = () => {
  UserInfoService();
  const token = useRecoilValue(AuthorizationTokenAtom);
  const myInfo = useRecoilValue(MyInfoAtom);
  const setMyInfo = useSetRecoilState(MyInfoAtom);
  const [metaAdAccounts, setMetaAdAccounts] = useState<MetaAdAccountType[]>([]);
  const [tikTokAdAccounts, setTikTokAdAccounts] = useState<TikTokAdAccountType[]>([]);
  const [googleAdAccounts, setGoogleAdAccounts] = useState<GoogleAdAccountType[]>([]);
  const [isMetaAdAccountsVisible, setMetaAdAccountsVisible] = useState(false);
  const [isTikTokAdAccountsVisible, setTikTokAdAccountsVisible] = useState(false);
  const [isGoogleAdAccountsVisible, setGoogleAdAccountsVisible] = useState(false);
  const [isUsersVisible, setUsersVisible] = useState(true);
  const [searchEmail, setSearchEmail] = useState('');
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState<{
    message: string;
    type: 'success' | 'error' | null;
  }>({ message: '', type: null });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<UserForDelete | null>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<UserForEdit | null>(null);
  const [cafe24AdAccounts, setCafe24AdAccounts] = useState<any[]>([]);
  const [isCafe24AccountsVisible, setIsCafe24AccountsVisible] = useState(false);
  const [selectedCafe24Account, setSelectedCafe24Account] = useState<any | null>(null);
  const [isCafe24ModalOpen, setIsCafe24ModalOpen] = useState(false);
  const [isDeleteCafe24ModalOpen, setIsDeleteCafe24ModalOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState<{
    message: string;
    type: 'success' | 'error' | null;
  }>({ message: '', type: null });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if(myInfo?.adAccount?.metaAdAccounts) setMetaAdAccounts(myInfo.adAccount.metaAdAccounts);
    if(myInfo?.adAccount?.tikTokAdAccounts) setTikTokAdAccounts(myInfo.adAccount.tikTokAdAccounts);
    if(myInfo?.adAccount?.googleAdAccounts) setGoogleAdAccounts(myInfo.adAccount.googleAdAccounts);
    if(myInfo?.adAccount?.cafe24AdAccounts) setCafe24AdAccounts(myInfo.adAccount.cafe24AdAccounts);
  }, [myInfo, token]);

  // 이메일 필터링 함수
  const filteredUsers = myInfo?.adAccount?.accountUsers?.filter(user => 
    user.email.toLowerCase().includes(searchEmail.toLowerCase())
  );

  // 사용자 관리 권한 체크 함수
  const hasUserManagePermission = myInfo?.type === 'ADMIN' || myInfo?.type === 'NORMAL_MASTER';
  
  // 유저 타입 옵션 (ADMIN 제외)
  const userTypeOptions = [
    { value: 'NORMAL_MASTER', label: '마스터' },
    { value: 'NORMAL_WRITEABLE', label: '읽기/쓰기 가능' },
    { value: 'NORMAL_READONLY', label: '읽기 전용' }
  ];

  // 유형별 설명을 한글로 변경
  const userTypeDescriptions = {
    'NORMAL_MASTER': '마스터 권한: 모든 기능 사용 및 사용자 관리 가능',
    'NORMAL_WRITEABLE': '일반 권한: 읽기/쓰기 기능 사용 가능',
    'NORMAL_READONLY': '읽기 전용: 읽기 전용 기능만 사용 가능'
  } as const;

  const handleUserAddSuccess = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/v1/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setMyInfo(data);
      }
    } catch (error) {
      console.error('Failed to fetch user info:', error);
    }
  };

  const handleUserEdit = (user: UserForEdit) => {
    setUserToEdit(user);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (user: UserForDelete) => {
    setUserToDelete(user);
    setDeleteModalOpen(true);
  };

  const handleUserDelete = async (userId: number) => {
    try {
      await deleteAccountUser(userId, token);
      
      setDeleteStatus({
        message: '사용자가 성공적으로 삭제되었습니다.',
        type: 'success'
      });

      await handleUserAddSuccess();
      setDeleteModalOpen(false);
      setUserToDelete(null);

      setTimeout(() => {
        setDeleteStatus({ message: '', type: null });
      }, 3000);

    } catch (err) {
      if (axios.isAxiosError(err)) {
        const status = err.response?.status;
        let errorMessage = '사용자 삭제 중 오류가 발생했습니다.';
        
        switch (status) {
          case 401:
            errorMessage = '인증이 만료되었습니다. 다시 로그인해주세요.';
            break;
          case 403:
            errorMessage = '사용자 삭제 권한이 없습니다.';
            break;
          case 404:
            errorMessage = '존재하지 않는 사용자입니다.';
            break;
        }
        
        setDeleteStatus({
          message: errorMessage,
          type: 'error'
        });
      } else {
        setDeleteStatus({
          message: '알 수 없는 오류가 발생했습니다.',
          type: 'error'
        });
      }

      setTimeout(() => {
        setDeleteStatus({ message: '', type: null });
      }, 3000);
    }
  };

  const handleUserTypeUpdate = async (newType: string) => {
    if (!userToEdit) return;

    try {
      await updateAccountUserType(userToEdit.id, newType, token);
      
      setDeleteStatus({
        message: '사용자 권한이 성공적으로 수정되었습니다.',
        type: 'success'
      });

      await handleUserAddSuccess();
      setEditModalOpen(false);
      setUserToEdit(null);

      setTimeout(() => {
        setDeleteStatus({ message: '', type: null });
      }, 3000);

    } catch (err) {
      if (axios.isAxiosError(err)) {
        const status = err.response?.status;
        let errorMessage = '사용자 권한 수정 중 오류가 발생했습니다.';
        
        switch (status) {
          case 401:
            errorMessage = '인증이 만료되었습니다. 다시 로그인해주세요.';
            break;
          case 403:
            errorMessage = '사용자 권한 수정 권한이 없습니다.';
            break;
          case 404:
            errorMessage = '존재하지 않는 사용자입니다.';
            break;
        }
        
        setDeleteStatus({
          message: errorMessage,
          type: 'error'
        });
      }

      setTimeout(() => {
        setDeleteStatus({ message: '', type: null });
      }, 3000);
    }
  };

  const handleCafe24Success = (message: string) => {
    setIsCafe24ModalOpen(false);
    setStatusMessage({
      message: message,
      type: 'success'
    });
    
    // 3초 후 메시지 제거
    setTimeout(() => {
      setStatusMessage({ message: '', type: null });
    }, 3000);

    // 기존 데이터 새로고침
    if (myInfo?.adAccount?.cafe24AdAccounts) {
      setCafe24AdAccounts([...myInfo.adAccount.cafe24AdAccounts]);
    }
  };

  const handleDeleteCafe24Account = async () => {
    try {
      await deleteCafe24Account(token);
      setIsDeleteCafe24ModalOpen(false);
      setStatusMessage({
        message: 'Cafe24 계정이 성공적으로 삭제되었습니다.',
        type: 'success'
      });
      
      setTimeout(() => {
        setStatusMessage({ message: '', type: null });
      }, 3000);

      if (myInfo?.adAccount?.cafe24AdAccounts) {
        const updatedAccounts = cafe24AdAccounts.filter(
          account => account.id !== selectedCafe24Account?.id
        );
        setCafe24AdAccounts(updatedAccounts);
      }
    } catch (error) {
      setStatusMessage({
        message: '계정 삭제 중 오류가 발생했습니다.',
        type: 'error'
      });
      
      setTimeout(() => {
        setStatusMessage({ message: '', type: null });
      }, 3000);
    }
  };

  const validatePassword = (pass: string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    return regex.test(pass);
  };

  const handlePasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPasswordError('');

    if (!validatePassword(password)) {
      setPasswordError('비밀번호는 대소문자, 특수문자를 포함하여 8자리 이상이어야 합니다.');
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError('비밀번호가 일치하지 않습니다.');
      return;
    }

    try {
      await updateUserPassword(password, token);
      setStatusMessage({
        message: '비밀번호가 성공적으로 변경되었습니다.',
        type: 'success'
      });
      setPassword('');
      setConfirmPassword('');
      
      setTimeout(() => {
        setStatusMessage({ message: '', type: null });
      }, 3000);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setStatusMessage({
          message: '비밀번호 변경 중 오류가 발생했습니다.',
          type: 'error'
        });
      }
      setTimeout(() => {
        setStatusMessage({ message: '', type: null });
      }, 3000);
    }
  };

  if (!myInfo.id) return <div className={S.loading}>Loading...</div>;

  return (
    <div className={S.container}>
      {(statusMessage.message || deleteStatus.message) && (
        <div className={`${S.statusMessage} ${S[statusMessage.type || deleteStatus.type || '']}`}>
          {statusMessage.message || deleteStatus.message}
        </div>
      )}
      
      <div className={S.section}>
        <h2 className={S.sectionTitle}>계정 정보</h2>
        <div className={S.card}>
          <div className={S.field}>
            <label>이메일</label>
            <input type="text" value={myInfo.email} readOnly />
          </div>
          <div className={S.field}>
            <label>계정 유형</label>
            <input type="text" value={myInfo.type} readOnly />
          </div>
        </div>
      </div>

      <div className={S.section}>
        <h2 className={S.sectionTitle}>비밀번호 설정</h2>
        <div className={S.card}>
          <form onSubmit={handlePasswordSubmit}>
            <div className={S.field}>
              <label>새 비밀번호</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="대소문자, 특수문자 포함 8자리 이상"
              />
            </div>
            <div className={S.field}>
              <label>비밀번호 확인</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="비밀번호를 다시 입력해주세요"
              />
            </div>
            {passwordError && (
              <div className={`${S.statusMessage} ${S.error}`} style={{ position: 'static', marginBottom: '1rem' }}>
                {passwordError}
              </div>
            )}
            <button type="submit" className={S.addButton}>
              비밀번호 변경
            </button>
          </form>
        </div>
      </div>

      <div className={S.section}>
        <h2 className={S.sectionTitle}>광고 계정 정보</h2>
        <div className={S.card}>
          <div className={S.field}>
            <label>계정명</label>
            <input type="text" value={myInfo?.adAccount?.name || ''} readOnly />
          </div>
          <div className={S.field}>
            <label>설명</label>
            <input type="text" value={myInfo?.adAccount?.description || ''} readOnly />
          </div>
          <div className={S.field}>
            <label>등급</label>
            <input type="text" value={myInfo?.adAccount?.tier || ''} readOnly />
          </div>

          <div className={S.platformSection}>
            <div className={S.platformHeader} onClick={() => setIsCafe24AccountsVisible(!isCafe24AccountsVisible)}>
              <div className={S.platformTitle}>
                <h3>Cafe24 계정</h3>
              </div>
              {isCafe24AccountsVisible ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>

            {isCafe24AccountsVisible && (
              <div className={S.platformContent}>
                {cafe24AdAccounts.length > 0 ? (
                  <div className={S.accountsList}>
                    {cafe24AdAccounts.map((account) => (
                      <div key={account.id} className={S.accountCard}>
                        <div className={S.accountInfo}>
                          <div>
                            <strong>계정 ID</strong>
                            {account.cafe24Id}
                          </div>
                          {account.cafe24LoginId && (
                            <div>
                              <strong>로그인 ID</strong>
                              {account.cafe24LoginId}
                            </div>
                          )}
                          <div>
                            <strong>계정 유형</strong>
                            {account.cafe24AccountType}
                          </div>
                          {account.description && (
                            <div>
                              <strong>설명</strong>
                              {account.description}
                            </div>
                          )}
                        </div>
                        {myInfo?.type !== 'NORMAL_READONLY' && (
                          <div className={S.accountActions}>
                            <button 
                              className={S.editButton}
                              onClick={() => {
                                setSelectedCafe24Account(account);
                                setIsCafe24ModalOpen(true);
                              }}
                            >
                              <IoPencil />
                            </button>
                            <button 
                              className={S.deleteButton}
                              onClick={() => {
                                setSelectedCafe24Account(account);
                                setIsDeleteCafe24ModalOpen(true);
                              }}
                            >
                              <IoTrash />
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={S.noAccounts}>
                    <p>연결된 Cafe24 계정이 없습니다.</p>
                    {myInfo?.type !== 'NORMAL_READONLY' && (
                      <button 
                        className={S.addButton}
                        onClick={() => {
                          setSelectedCafe24Account(null);
                          setIsCafe24ModalOpen(true);
                        }}
                      >
                        <IoAdd /> Cafe24 계정 연결
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={S.platformSection}>
            <div className={S.platformHeader} onClick={() => setMetaAdAccountsVisible(!isMetaAdAccountsVisible)}>
              <div className={S.platformTitle}>
                <SiMeta className={S.platformIcon} />
                Meta 광고 계정
              </div>
              {isMetaAdAccountsVisible ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>
            {isMetaAdAccountsVisible && (
              <div className={S.accountsList}>
                {metaAdAccounts.map((account) => (
                  <div key={account.metaAccountId} className={S.accountCard}>
                    <div className={S.field}>
                      <label>계정명</label>
                      <input type="text" value={account.name} readOnly />
                    </div>
                    <div className={S.field}>
                      <label>설명</label>
                      <input type="text" value={account.description} readOnly />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={S.platformSection}>
            <div className={S.platformHeader} onClick={() => setTikTokAdAccountsVisible(!isTikTokAdAccountsVisible)}>
              <div className={S.platformTitle}>
                <SiTiktok className={S.platformIcon} />
                TikTok 광고 계정
              </div>
              {isTikTokAdAccountsVisible ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>
            {isTikTokAdAccountsVisible && (
              <div className={S.accountsList}>
                {tikTokAdAccounts.map((account) => (
                  <div key={account.advertiserId} className={S.accountCard}>
                    <div className={S.field}>
                      <label>계정명</label>
                      <input type="text" value={account.name} readOnly />
                    </div>
                    <div className={S.field}>
                      <label>설명</label>
                      <input type="text" value={account.description} readOnly />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={S.platformSection}>
            <div className={S.platformHeader} onClick={() => setGoogleAdAccountsVisible(!isGoogleAdAccountsVisible)}>
              <div className={S.platformTitle}>
                <SiGoogle className={S.platformIcon} />
                Google 광고 계정
              </div>
              {isGoogleAdAccountsVisible ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>
            {isGoogleAdAccountsVisible && (
              <div className={S.accountsList}>
                {googleAdAccounts.map((account) => (
                  <div key={account.customerId} className={S.accountCard}>
                    <div className={S.field}>
                      <label>계정명</label>
                      <input type="text" value={account.name} readOnly />
                    </div>
                    <div className={S.field}>
                      <label>설명</label>
                      <input type="text" value={account.description} readOnly />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={S.section}>
        <div className={S.sectionHeader}>
          <h2 className={S.sectionTitle}>계정 사용자</h2>
          {(myInfo.type === 'ADMIN' || myInfo.type === 'NORMAL_MASTER') && (
            <button 
              className={S.addButton}
              onClick={() => setAddModalOpen(true)}
            >
              사용자 추가
            </button>
          )}
        </div>
        {isUsersVisible && (
          <div className={S.userTableCard}>
            <div className={S.searchWrapper}>
              <input
                type="text"
                placeholder="이메일로 검색"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
                className={S.searchInput}
              />
            </div>
            <table className={S.userTable}>
              <thead>
                <tr>
                  <th>이메일</th>
                  <th>상태</th>
                  <th>유형</th>
                  {(myInfo.type === 'ADMIN' || myInfo.type === 'NORMAL_MASTER') && <th>관리</th>}
                </tr>
              </thead>
              <tbody>
                {filteredUsers?.map((user) => (
                  <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>{user.status}</td>
                    <td>{UserTypeEnum[user.type]}</td>
                    {(myInfo.type === 'ADMIN' || myInfo.type === 'NORMAL_MASTER') && (
                      <td className={S.actionCell}>
                        <div className={S.actionButtons}>
                          <button 
                            className={S.editButton}
                            onClick={() => handleUserEdit({
                              id: user.id,
                              email: user.email,
                              type: user.type
                            })}
                          >
                            수정
                          </button>
                          <button 
                            className={S.deleteButton}
                            onClick={() => handleDeleteClick({
                              id: user.id,
                              email: user.email
                            })}
                          >
                            삭제
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <UserManageModal
        isOpen={isAddModalOpen}
        onClose={() => setAddModalOpen(false)}
        onSuccess={handleUserAddSuccess}
      />

      <DeleteConfirmModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setUserToDelete(null);
        }}
        onConfirm={() => userToDelete && handleUserDelete(userToDelete.id)}
        email={userToDelete?.email || ''}
      />

      <EditUserTypeModal
        isOpen={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setUserToEdit(null);
        }}
        onConfirm={handleUserTypeUpdate}
        currentType={userToEdit?.type || ''}
        email={userToEdit?.email || ''}
      />

      <Cafe24AccountModal
        isOpen={isCafe24ModalOpen}
        onClose={() => setIsCafe24ModalOpen(false)}
        onSuccess={handleCafe24Success}
        initialData={selectedCafe24Account}
      />

      <DeleteConfirmModal
        isOpen={isDeleteCafe24ModalOpen}
        onClose={() => setIsDeleteCafe24ModalOpen(false)}
        onConfirm={handleDeleteCafe24Account}
        message={`Cafe24 계정 '${selectedCafe24Account?.cafe24Id}'을(를) 삭제하시겠습니까?`}
      />
    </div>
  )
};

export default MyAccount;