import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'
import _ from 'lodash'
import { ClipLoader } from 'react-spinners'
import { Cafe24ContentsType, Cafe24SyncStatusType } from '../../../../types/MetaDataTypes'
import {
  fetchRawData,
  uploadCafe24Excel,
  fetchUploadedDate,
  fetchCafe24SyncStatus,
  syncCafe24Data,
} from '../../../../repository/MetaDataRepository'
import { UserInfoService } from '../../../../service/UserInfoService'
import { AuthorizationTokenAtom } from '../../../../recoil/Authorization.recoil'
import S from './RawData.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { FaSyncAlt } from 'react-icons/fa'

const columns: Column<Cafe24ContentsType>[] = [
  { Header: '광고매체', accessor: 'adMedia' },
  { Header: '날짜', accessor: 'date' },
  { Header: '방문수', accessor: 'visitCount' },
  { Header: '방문비율', accessor: 'visitRatio' },
  { Header: '구매건수', accessor: 'purchaseCount' },
  { Header: '구매율', accessor: 'purchaseRatio' },
  { Header: '매출액', accessor: 'revenue' },
  { Header: '구매당 매출', accessor: 'revenuePerPurchase' },
  { Header: '방문당 매출', accessor: 'revenuePerVisit' },
]

const RawData: React.FC = () => {
  UserInfoService()
  const token = useRecoilValue(AuthorizationTokenAtom)

  // States
  const [data, setData] = useState<Cafe24ContentsType[]>([])
  const [startDate, setStartDate] = useState<Date>(
    dayjs().subtract(1, 'day').toDate(),
  )
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate())
  const [uploadDate, setUploadDate] = useState<Date>(dayjs().toDate())
  const [mediaCode, setMediaCode] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(30)
  const [totalElements, setTotalElements] = useState(0)
  const [isLast, setIsLast] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dateAvailability, setDateAvailability] = useState<{[key: string]: boolean}>({});
  const [loadedMonths, setLoadedMonths] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncStatus, setSyncStatus] = useState<Cafe24SyncStatusType | null>(null);

  useEffect(() => {
    if (!_.isEmpty(token)) {
      fetchData()
    }
  }, [currentPage, pageSize])

  const fetchData = () => {
    setIsLoading(true)
    fetchRawData(
      token,
      mediaCode,
      dayjs(startDate).format('YYYY-MM-DD'),
      dayjs(endDate).format('YYYY-MM-DD'),
      currentPage,
      pageSize,
    )
      .then((res) => {
        if (res && res.content) {
          setData(res.content)
          setCurrentPage(res.pageNumber)
          setPageSize(res.pageSize)
          setTotalElements(res.totalElements)
          setIsLast(res.isLast)
          setTotalPages(res.totalPages)
          setTablePageSize(pageSize)
        } else {
          setData([])
          setTotalElements(0)
          setTotalPages(0)
          setIsLast(true)
        }
      })
      .catch((e) => {
        console.error(e.message)
        alert('데이터 조회 실패!')
        setData([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert('파일을 선택해주세요.');
      return;
    }

    const selectedDate = dayjs(uploadDate);
    const today = dayjs().endOf('day');

    if (selectedDate.isAfter(today)) {
      alert('오늘 이후의 날짜는 선택할 수 없습니다.');
      return;
    }

    setIsUploading(true);
    try {
      await uploadCafe24Excel(
        token,
        selectedFile,
        selectedDate.format('YYYY-MM-DD'),
      )
        .then((res) => res && alert('파일 업로드 성공!'))
        .catch((e) => alert('파일 업로드 실패 (reason :' + e.message))
      fetchData()
    } catch (error) {
      console.error('File upload error:', error)
      alert('파일 업로드 실패!')
    } finally {
      setIsUploading(false);
    }
  }

  const handleSearch = () => {
    setCurrentPage(0)
    fetchData()
  }

  // Table Configuration
  const tableInstance = useTable(
    {
      columns: useMemo(() => columns, []),
      data: data || [],
      initialState: { pageIndex: 0, pageSize },
    },
    useSortBy,
    usePagination,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize: setTablePageSize,
  } = tableInstance

  // 업로드된 날짜 데이터를 가져오는 함수
  const fetchMonthlyUploadedDates = (date: Date) => {
    // 달력에 표시되는 첫 날짜와 마지막 날짜를 계산
    const calendarStart = dayjs(date).startOf('month').startOf('week');
    const calendarEnd = dayjs(date).endOf('month').endOf('week');
    const monthKey = dayjs(date).format('YYYY-MM');
    
    if (loadedMonths.includes(monthKey)) {
      return;
    }
    
    fetchUploadedDate(
      token,
      calendarStart.format('YYYY-MM-DD'),
      calendarEnd.format('YYYY-MM-DD')
    )
      .then((response) => {
        if (response && Array.isArray(response)) {
          setDateAvailability(prev => ({
            ...prev,
            ...response.reduce((acc, curr) => {
              acc[curr.date] = curr.hasData;
              return acc;
            }, {} as {[key: string]: boolean})
          }));

          setLoadedMonths(prev => [...prev, monthKey]);
        }
      })
      .catch((error) => {
        console.error('Error fetching uploaded dates:', error);
      });
  };

  // DatePicker 커스텀 스타일
  const renderDayContents = (day: number, date: Date) => {
    const dateString = dayjs(date).format('YYYY-MM-DD');
    const hasData = dateAvailability[dateString];
    
    return (
      <div className={S.dayContainer}>
        <span>{day}</span>
        {hasData !== undefined && (
          <div 
            className={`${S.dataIndicator} ${
              hasData ? S.hasData : S.noData
            }`} 
          />
        )}
      </div>
    );
  };

  // 월이 변경될 때마다 업로드된 날짜 정보를 가져옴
  const handleMonthChange = (date: Date) => {
    fetchMonthlyUploadedDates(date);
  };

  // 컴포넌트 마운트 시 현재 월 데이터 로드
  useEffect(() => {
    if (!_.isEmpty(token)) {
      const now = new Date();
      fetchMonthlyUploadedDates(now);
    }
  }, [token]);

  // 동기화 상태 조회
  const fetchSyncStatus = useCallback(() => {
    if (!_.isEmpty(token)) {
      fetchCafe24SyncStatus(token)
        .then((res) => {
          setSyncStatus(res);
        })
        .catch((error) => {
          console.error('동기화 상태 조회 실패:', error);
        });
    }
  }, [token]);

  // 컴포넌트 마운트 시와 10초마다 동기화 상태 업데이트
  useEffect(() => {
    fetchSyncStatus();
    
    const intervalId = setInterval(() => {
      fetchSyncStatus();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchSyncStatus]);

  // 동기화 버튼 클릭 핸들러
  const handleSyncClick = async () => {
    if (!syncStatus?.syncAble) {
      alert(syncStatus?.description || '현재 동기화할 수 없습니다.');
      return;
    }

    const selectedDate = dayjs(uploadDate);
    const today = dayjs().endOf('day');

    if (selectedDate.isAfter(today)) {
      alert('오늘 이후의 날짜는 선택할 수 없습니다.');
      return;
    }

    const syncDate = selectedDate.format('YYYY-MM-DD');
    
    // 날짜 유효성 검사
    if (!syncDate) {
      alert('데이터 기준일을 선택해주세요.');
      return;
    }

    setIsSyncing(true);
    try {
      const response = await syncCafe24Data(
        token,
        syncDate,
        syncDate
      );
      if (response.success) {
        alert('동기화 요청이 성공되었습니다.\n데이터 동기화는 약 2~3분 정도 소요됩니다.');
        fetchSyncStatus();
        fetchData();
      } else {
        alert('동기화 실패: ' + response.message);
      }
    } catch (error) {
      console.error('동기화 실패:', error);
      alert('동기화 중 오류가 발생했습니다.');
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <div className={S.container}>
      <header className={S.pageHeader}>
        <h1>광고 데이터</h1>
        <p className={S.pageDescription}>
          광고 채널별 실적 데이터를 관리하고 분석합니다.
        </p>
      </header>

      <section className={S.uploadSection}>
        <div className={S.uploadGroup}>
          <div className={S.uploadItem}>
            <label htmlFor="fileUpload">데이터 파일</label>
            <div className={S.fileInputWrapper}>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                id="fileUpload"
                disabled={isUploading}
              />
            </div>
          </div>
          <div className={S.uploadDateItem}>
            <label htmlFor="uploadDate">데이터 기준일</label>
            <DatePicker
              id="uploadDate"
              selected={uploadDate}
              onChange={(date) => date && setUploadDate(date)}
              dateFormat="yyyy-MM-dd"
              className={S.customDatePicker}
              renderDayContents={renderDayContents}
              onMonthChange={handleMonthChange}
              disabled={isUploading}
            />
          </div>
          <div className={S.buttonContainer}>
            <button 
              onClick={handleFileUpload} 
              className={`${S.uploadBtn} ${isUploading ? S.loading : ''}`}
              disabled={isUploading}
            >
              {isUploading ? (
                <div className={S.loadingWrapper}>
                  <div className={S.loader} />
                  <span>업로드 중...</span>
                </div>
              ) : (
                '업로드'
              )}
            </button>
            <div className={S.syncInfo}>
              <button
                className={`${S.syncBtn} ${isSyncing ? S.syncing : ''}`}
                onClick={handleSyncClick}
                disabled={isSyncing || !syncStatus?.syncAble}
              >
                {isSyncing ? <FaSyncAlt className={S.syncIcon} /> : '동기화'}
              </button>
              {syncStatus && (
                <div className={S.syncStatus}>
                  <span className={syncStatus.syncAble ? S.syncSuccess : S.syncError}>
                    {syncStatus.description}
                  </span>
                  {syncStatus.lastSyncDate && (
                    <span className={S.syncTime}>
                      최근 동기화: {dayjs(syncStatus.lastSyncDate).format('YYYY-MM-DD HH:mm:ss')}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className={S.searchSection}>
        <div className={S.searchControls}>
          <div className={S.datePicker}>
            <div className={S.datePickerItem}>
              <label htmlFor="startDate">시작일</label>
              <DatePicker
                id="startDate"
                selected={startDate}
                onChange={(date) => date && setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                className={S.customDatePicker}
              />
            </div>
            <div className={S.datePickerItem}>
              <label htmlFor="endDate">종료일</label>
              <DatePicker
                id="endDate"
                selected={endDate}
                onChange={(date) => date && setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                className={S.customDatePicker}
              />
            </div>
          </div>
          <div className={S.mediaFilter}>
            <label htmlFor="mediaCode">매체 구분</label>
            <input
              type="text"
              id="mediaCode"
              value={mediaCode}
              onChange={(e) => setMediaCode(e.target.value)}
              className={S.searchInput}
              placeholder="매체 코드 입력"
            />
          </div>
          <div className={S.searchButtonContainer}>
            <button onClick={handleSearch} className={S.searchBtn}>
              조회
            </button>
          </div>
        </div>
      </section>

      <section className={S.dataSection}>
        <div className={S.dataInfo}>
          <div className={S.totalCount}>
            총 {totalElements.toLocaleString()}건
          </div>
        </div>
        <div className={S.tableWrapper}>
          <table {...getTableProps()} className={S.table}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className={S.pagination}>
          <div className={S.paginationControls}>
            <button
              onClick={() => {
                setCurrentPage(0);
                gotoPage(0);
              }}
              disabled={currentPage === 0}
              className={S.paginationButton}
            >
              처음
            </button>
            <button
              onClick={() => {
                const newPage = Math.max(0, currentPage - 1);
                setCurrentPage(newPage);
                gotoPage(newPage);
              }}
              disabled={currentPage === 0}
              className={S.paginationButton}
            >
              이전
            </button>
            <span className={S.pageInfo}>
              {currentPage + 1} / {totalPages} 페이지
            </span>
            <button
              onClick={() => {
                const newPage = Math.min(totalPages - 1, currentPage + 1);
                setCurrentPage(newPage);
                gotoPage(newPage);
              }}
              disabled={isLast}
              className={S.paginationButton}
            >
              다음
            </button>
            <button
              onClick={() => {
                const lastPage = totalPages - 1;
                setCurrentPage(lastPage);
                gotoPage(lastPage);
              }}
              disabled={isLast}
              className={S.paginationButton}
            >
              마지막
            </button>
          </div>
          <div className={S.pageJump}>
            <span>페이지 이동</span>
            <input
              type="number"
              min={1}
              max={totalPages}
              defaultValue={currentPage + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                setCurrentPage(page);
                gotoPage(page);
              }}
              className={S.pageJumpInput}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default RawData
