import Header from '../common/Header'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ErrorBoundary from '../common/ErrorBoundary'
import React, { useEffect } from 'react';
import S from './Router.module.scss';
import Login from '../awake/Login'
import Auth from '../awake/Auth'
import Sidebar from '../common/Sidebar'
import RawData from '../awake/Data/RawData'
import MetaSettings from '../awake/Settings'
import MyAccount from '../awake/My/Account/MyAccount'
import MetaSetsData from '../awake/Data/Meta/MetaSets'
import MetaAdsData from '../awake/Data/Meta/MetaAds'
import MetaCampaigns from '../awake/Data/Meta/MetaCampaigns'
import Main from '../awake/Main'
import Footer from '../common/Footer'
import Terms from '../common/Footer/Terms'
import Privacy from '../common/Footer/Privacy'
import TikTokAdGroupData from '../awake/Data/Tiktok/TikTokAdGroup'
import TikTokAdsData from '../awake/Data/Tiktok/TikTokAds'
import Campaign from '../awake/Data/Google/Campaign'
import AdGroup from '../awake/Data/Google/AdGroup'

const Router = () => {
  const location = useLocation()
  return (
    <div className={S.container}>
      <div className={S.contentWrapper}>
        <ErrorBoundary>
          <React.Suspense fallback={<></>}>
            {!location.pathname.startsWith('/login') && <HeaderRouter />}
          </React.Suspense>
        </ErrorBoundary>
        <ErrorBoundary>
          <React.Suspense fallback={<></>}>
            <BodyWrapper />
          </React.Suspense>
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <React.Suspense fallback={<></>}>
          <FooterRouter />
        </React.Suspense>
      </ErrorBoundary>
    </div>
  )
}

const HeaderRouter = () => (
  <Routes>
    <Route path={'/*'} element={<Header />} />
  </Routes>
)

const BodyWrapper = () => {
  const location = useLocation()

  return (
    <div className={S.bodyWrapper}>
      {!location.pathname.startsWith('/login') && <Sidebar />}
      <BodyRouter />
    </div>
  )
}

const BodyRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/data/raw" element={<RawData />} />

      <Route path="/data" element={<MetaCampaigns />} />
      <Route path="/data/meta/campaigns" element={<MetaCampaigns />} />
      <Route path="/data/meta/sets" element={<MetaSetsData />} />
      <Route path="/data/meta/ad" element={<MetaAdsData />} />

      <Route path="/data/tiktok/ad-group" element={<TikTokAdGroupData />} />
      <Route path="/data/tiktok/ad" element={<TikTokAdsData />} />

      <Route path="/data/google/campaign" element={<Campaign />} />
      <Route path="/data/google/ad-group" element={<AdGroup />} />

      {/*<Route path="/ad-settings/meta" element={<MetaSettings />} />*/}

      <Route path="/my-info" element={<MyAccount />} />
      <Route path="/my-info/account" element={<MyAccount />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/privacy" element={<Login />} />
      <Route path="/login/terms" element={<Login />} />
      <Route path="/auth" element={<Auth />} />

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  )
}

const FooterRouter = () => (
  <Routes>
    <Route path={'/*'} element={<Footer />} />
  </Routes>
)

export default Router;