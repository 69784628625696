import React, { useEffect, useState } from 'react'
import styles from './Footer.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Terms from './Terms'
import Privacy from './Privacy'

const Footer: React.FC = () => {
  const location = useLocation();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    // URL에 privacy나 terms가 포함되어 있으면 해당 팝업을 엽니다
    if (location.pathname === '/login/privacy') {
      setShowPrivacy(true);
    } else if (location.pathname === '/login/terms') {
      setShowTerms(true);
    }
  }, [location.pathname]);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerLinks}>
        <button onClick={() => setShowTerms(true)} className={styles.footerButton}>
          이용약관
        </button>
        <button onClick={() => setShowPrivacy(true)} className={styles.footerButton}>
          개인정보 처리방침
        </button>
      </div>

      {/* 이용약관 팝업 */}
      {showTerms && <Terms closePopup={() => setShowTerms(false)} />}

      {/* 개인정보 처리방침 팝업 */}
      {showPrivacy && <Privacy closePopup={() => setShowPrivacy(false)} />}

      <div className={styles.companyInfo}>
        <p>
          상호명: 주식회사 애드커넥션
          <br />
          대표자: 박우일
          <br />
          사업자등록번호: 721-87-02315
          <br />
          주소: 서울시 영등포구 선유로 3길 10 하우스디비즈 806호
          <br />
          이메일: ad@adconnection.co.kr
        </p>
      </div>
    </footer>
  );
};

export default Footer;
