import { RepositoryUtils } from '../utils/RepositoryUtils'
import {
  AD_STATUS,
  RawDataType,
  TikTokAdType,
  TikTokBudgetEditType,
  TikTokSelectBoxResponseType,
  TikTokStatusEditType,
  TikTokAdDetailType,
  TikTokAdGroupType,
  TikTokAdGroupDetailType,
  AdHistoryResponseType, MemoUpdateType,
} from '../types/TikTokDataTypes'
import { ApiPostResponseType, ApiPutResponseType } from '../types/ApiResponseType'
import { TikTokAdAccountType } from '../types/LoginTypes'

const apiDomain = process.env.REACT_APP_API_DOMAIN

export const fetchTikTokAdGroupData = (token: string, queryParams: string) => {
  return RepositoryUtils.axiosGet<TikTokAdGroupType[]>(
    `${apiDomain}/api/v1/ads/tiktok/ad-group/insights/_search?${queryParams}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const fetchTikTokAccounts = (token: string) => {
  return RepositoryUtils.axiosGet<TikTokAdAccountType[]>(`${apiDomain}/api/v1/ads/tiktok/accounts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
}

export const fetchTikTokAdsData = (token: string, queryParams: string) => {
  return RepositoryUtils.axiosGet<TikTokAdType[]>(
    `${apiDomain}/api/v1/ads/tiktok/ads/insights/_search?${queryParams}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const fetchTikTokAdDetails = (token: string, adId: string, from: string, to: string) => {
  return RepositoryUtils.axiosGet<TikTokAdDetailType>(
    `${apiDomain}/api/v1/ads/tiktok/ads?adId=${adId}&from=${from}&to=${to}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export const fetchTikTokAdGroupDetails = (token: string, adGroupId: string, from: string, to: string) => {
  return RepositoryUtils.axiosGet<TikTokAdGroupDetailType>(
    `${apiDomain}/api/v1/ads/tiktok/ad-groups?adGroupId=${adGroupId}&from=${from}&to=${to}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export const changeAdStatus = (token: string, adId: string, status: AD_STATUS, memo: string | null = null) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/tiktok/ads/status`,
    {
      id: adId,
      status: status,
      memo: memo
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const changeAdGroupStatus = (token: string, adGroupId: string, status: AD_STATUS, memo: string | null) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/tiktok/ad-group/status`,
    {
      id: adGroupId,
      status: status,
      memo: memo
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const changeAdGroupDailyBudget = (token: string, adGroupId: string, dailyBudget: number, memo: string | null) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/tiktok/ad-group/daily-budget`,
    {
      id: adGroupId,
      dailyBudget: dailyBudget,
      memo: memo
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const tiktokBulkChangeAdGroupDailyBudget = (token: string, requestPayload: TikTokBudgetEditType[]) => {
  return RepositoryUtils.axiosPut<ApiPutResponseType[]>(
    `${apiDomain}/api/v1/ads/tiktok/ad-group/daily-budget/many`,
    requestPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const tiktokBulkChangeAdGroupStatus = (token: string, requestPayload: TikTokStatusEditType[]) => {
  return RepositoryUtils.axiosPut<ApiPutResponseType[]>(
    `${apiDomain}/api/v1/ads/tiktok/ad-group/status/many`,
    requestPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const tiktokBulkChangeAdStatus = (token: string, requestPayload: TikTokStatusEditType[]) => {
  return RepositoryUtils.axiosPut<ApiPutResponseType[]>(
    `${apiDomain}/api/v1/ads/tiktok/ads/status/many`,
    requestPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}


export const fetchAdHistories = (token: string, accountId: string, adId: string) => {
  return RepositoryUtils.axiosGet<AdHistoryResponseType>(
    `${apiDomain}/api/v1/ads/tiktok/histories/ad?advertiserId=${accountId}&adId=${adId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const fetchAdGroupHistories = (token: string, accountId: string, adGroupId: string) => {
  return RepositoryUtils.axiosGet<AdHistoryResponseType>(
    `${apiDomain}/api/v1/ads/tiktok/histories/ad-group?advertiserId=${accountId}&adGroupId=${adGroupId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const syncDataManually = (token: string, from: string, to: string) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/tiktok/sync?from=${from}&to=${to}`,{},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export const updateMemo = (token: string, adId: string, memo: string) => {
  return RepositoryUtils.axiosPut<ApiPostResponseType>(
    `${apiDomain}/api/v1/ads/tiktok/ads/memo`,{
      id: adId,
      memo: memo,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
}

export interface TikTokSyncStatusType {
  syncAble: boolean;
  description: string;
  lastSyncDate: string | null;
}

export const fetchTikTokSyncStatus = (token: string, advertiserId: string): Promise<TikTokSyncStatusType> => {
  return RepositoryUtils.axiosGet<TikTokSyncStatusType>(
    `${apiDomain}/api/v1/ads/tiktok/sync`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        advertiserId
      }
    }
  )
}

//
// export const uploadCafe24Excel = (token: string, file: File, date: String) => {
//   return RepositoryUtils.axiosPost<ApiPostResponseType>(`${apiDomain}/api/v1/ads/cafe24/upload?date=${date}`,
//     {file: file}, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'multipart/form-data',
//     },
//   })
// }

